import React from "react"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { PreviewSidebar } from "./PreviewSidebar"
import { getQuestion } from "~/store/questions/selectors"
import { getAnswers } from "~/store/answers/selectors"
import useMedia from "~/hooks/useMedia"
import { questionToPage } from "./advisorModelToPagePreview"

export const QuestionPreview: React.FC<{
  questionId: string
}> = ({ questionId }) => {
  const question = useSelector((state: ApplicationState) =>
    getQuestion(state, questionId)
  )

  const media = useMedia(
    { type: "question", id: questionId },
    question?.mediaType || ""
  )

  const answers = useSelector((state: ApplicationState) => {
    if (!question) return []
    const answers_ = getAnswers(state, question)
    return answers_ || []
  })
  if (!question) return null

  const questionToPreview = questionToPage(question, answers, media)

  return <PreviewSidebar preview={questionToPreview} />
}
