import { ThunkDispatch } from "redux-thunk"
import { AnyAction } from "redux"
import {
  deleteAdvisor as deleteAdvisorAct,
  deleteAdvisorFailure,
  deleteAdvisorSuccess,
  fetchAdvisor,
  fetchAdvisorFailure,
  fetchAdvisorSuccess,
  newAdvisorSuccess,
  updateAdvisor as updateAdvisorAct,
  updateAdvisorFailure,
  updateAdvisorSuccess,
} from "./actions"
import { advisors } from "~/api"
import Advisor, { defaultAdvisor, internalToExternal } from "~/lib/Advisor"
import { UUID } from "~/store/types"
import { ApplicationState } from "~/store"
import { getAdvisor } from "~/store/advisors/selectors"
import {
  getQuestions,
  Question,
  QuestionColor,
  QuestionMediaType,
  QuestionType,
} from "~/store/questions"
import { getRules } from "~/store/rules/selectors"
import { getAnswers } from "~/store/answers/selectors"
import {
  requestFailure,
  requestSuccess,
  startRequest,
} from "~/actions/requestStatus/actions"
import { ApiError } from "~/api/APIBase"
import { getScreens } from "~/store/screens/selectors"
import { isAdvisorLoading } from "~/store/advisors/selectors"
import { notify } from "~/actions/notifications"
import { ApiContext, OrganisationId } from "~/api/types"
import { Answer } from "~/store/answers/types"
import { v4 as uuid } from "uuid"

/**
 * Stub for fetching an Advisor from the API. Uses a fixed Mock.
 */
export const fetchAdvisorRoutine = (
  requestId: UUID,
  advisorId: string,
  context: { token: string; organisationId: OrganisationId },
  opts: { logout: () => void }
) => {
  return (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    const state = getState()
    if (isAdvisorLoading(state, advisorId)) {
      return
    }
    dispatch(fetchAdvisor(advisorId))

    dispatch(startRequest(requestId))
    advisors
      .get(advisorId, context.token, context.organisationId)
      .then((externalAdvisor) => {
        const { advisor, questions, answers, rules, screens } =
          Advisor.externalToInternalExpanded(externalAdvisor)
        dispatch(
          fetchAdvisorSuccess(advisor, questions, rules, answers, screens)
        )
      })
      .then(() => dispatch(requestSuccess(requestId)))
      .catch((error) => {
        console.error(error)
        dispatch(requestFailure(requestId))
        dispatch(fetchAdvisorFailure(advisorId))
        if (error.status === 401) opts.logout()
        return error
      })
  }
}

type QuestionAndAnswers = [[Question, Answer[]][], number]
type SuggestedQuestion = {
  question: string
  answers: {
    answer: string
    helpText: string
    matchingFeatureLabel: string | undefined
    isNoPreference: boolean
  }[]
  multiple: boolean
  helpText: string
  label: string
}
export function suggestedConversationToQuestions(
  suggestion: SuggestedQuestion[]
) {
  const suggestions = suggestion.reduce(
    (acc, suggestion) => {
      const [list, index] = acc

      const answers = suggestion.answers.map((a) => {
        const answer: Answer = {
          id: uuid(),
          title: a.answer,
          helpText: a.helpText,
          matchingFeatureLabel: a.matchingFeatureLabel || "",
          isNeutralAnswer: a.isNoPreference,
        }
        return answer
      })
      const question: Question = {
        id: uuid(),
        answers: answers.map((a) => a.id),
        helpText: suggestion.helpText,
        type: suggestion.multiple ? QuestionType.Multi : QuestionType.Single,
        title: suggestion.question,
        label: suggestion.label || "Question " + index,
        color: QuestionColor.Color1,
        next: "",
        rules: [],
        mediaType: QuestionMediaType.NO_MEDIA,
        config: { filter: false },
      }
      return [[...list, [question, answers]], index + 1] as QuestionAndAnswers
    },
    [[], 1] as QuestionAndAnswers
  )[0]

  const [questions, answers]: [Question[], Answer[]] = suggestions
    .map(([q, answers], index) => {
      const next = (suggestions[index + 1] || [{}])[0]?.id || "ADVICE"
      const question = {
        ...q,
        next: next,
      } as Question
      return [question, answers] as [Question, Answer[]]
    })
    .reduce(
      ([qs, as], [q, answers]) => {
        return [
          [...qs, q],
          [...as, ...answers],
        ]
      },
      [[], []] as [Question[], Answer[]]
    )
  const nodes = Object.fromEntries(questions.map((q) => [q.id, q.next]))
  return { questions: questions, answers: answers, nodes: nodes }
}

export const createNewAdvisor =
  (
    requestId: UUID,
    advisor: {
      name?: string
      productLabel?: string
      icon?: string
      advisorId?: string
      locales?: string[]
      styleTemplateId?: string
    },
    opts: {
      context: ApiContext
      history?: any
      logout?: () => void
    },
    suggestedConversation: SuggestedQuestion[] = []
  ) =>
  (dispatch) => {
    const styleTemplateId = advisor.styleTemplateId
    const newAdvisor = defaultAdvisor(
      advisor.name,
      advisor.productLabel,
      advisor.icon,
      advisor.advisorId,
      { locales: advisor.locales }
    )
    dispatch(startRequest(requestId))
    const { questions, answers, nodes } = suggestedConversationToQuestions(
      suggestedConversation
    )
    const newExternalAdvisor = internalToExternal(
      newAdvisor,
      questions,
      answers,
      [],
      newAdvisor.screens,
      { nodes: nodes, rules: [], proposedChanges: {} }
    )

    advisors
      .create(
        newAdvisor.id,
        newExternalAdvisor,
        opts.context.token,
        opts.context.organisationId
      )
      .then((externalAdvisor) => {
        const { advisor, questions, answers, rules } =
          Advisor.externalToInternalExpanded(externalAdvisor)

        if (styleTemplateId) {
          advisors.assignTemplate(
            newAdvisor.id,
            styleTemplateId,
            opts.context.token,
            opts.context.organisationId
          )
        }

        dispatch(
          newAdvisorSuccess(
            advisor,
            questions,
            rules,
            answers,
            newAdvisor.screens
          )
        )
        opts.history && opts.history.push(`/advisors/${advisor.id}/flow`)
      })
      .then(() => dispatch(requestSuccess(requestId)))
      .catch((error: ApiError) => {
        dispatch(requestFailure(requestId, error))
        if (error.status === 401) opts.logout && opts.logout()
        return error
      })
  }

export const updateAdvisorRoutine =
  (
    context: ApiContext,
    advisorId: UUID,
    requestId: UUID,
    opts: { logout: () => void }
  ) =>
  (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => ApplicationState
  ) => {
    const state = getState()
    const advisor = getAdvisor(state, advisorId)

    if (advisor) {
      dispatch(updateAdvisorAct(advisorId))

      const questions = getQuestions(state, advisor.questions)
      const answers = questions.flatMap((q) => getAnswers(state, q))
      const rules = questions.flatMap((q) => getRules(state, q.rules))
      const screens = getScreens(state, advisor.id)

      const externalAdvisor = Advisor.internalToExternal(
        advisor,
        questions,
        answers,
        rules,
        screens!,
        state.flow[advisorId]
      )

      dispatch(startRequest(requestId))
      advisors
        .update(externalAdvisor, context.token, context.organisationId)
        .then((externalAdvisor) => {
          const { advisor, questions, answers, rules } =
            Advisor.externalToInternalExpanded(externalAdvisor)
          dispatch(
            updateAdvisorSuccess(advisor, questions, rules, answers, screens!)
          )
          dispatch(requestSuccess(requestId))
        })
        .catch((error) => {
          dispatch(updateAdvisorFailure(advisorId))
          dispatch(requestFailure(requestId))
          notifyIfPermissionChanged(error, dispatch)
          if (error.status === 401) opts.logout()

          return error
        })
    }
  }

export const deleteAdvisorRoutine =
  (
    requestId: UUID,
    advisorId: UUID,
    context: ApiContext,
    opts: { logout: () => void }
  ) =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(deleteAdvisorAct(advisorId))
    dispatch(startRequest(requestId))
    advisors
      .remove(advisorId, context.token, context.organisationId)
      .then(() => {
        dispatch(deleteAdvisorSuccess(advisorId))
        dispatch(requestSuccess(requestId))
      })
      .catch((error) => {
        dispatch(deleteAdvisorFailure(advisorId))
        dispatch(requestFailure(requestId))
        if (error.status === 401) opts.logout()
        return error
      })
  }

export const autoSaveAdvisor =
  (
    state: ApplicationState,
    advisorId: UUID,
    opts: {
      context: ApiContext
      finishCallback: () => void
    }
  ) =>
  (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const advisor = getAdvisor(state, advisorId)

    if (advisor) {
      dispatch(updateAdvisorAct(advisorId))

      const questions = getQuestions(state, advisor.questions)
      const answers = questions.flatMap((q) => getAnswers(state, q))
      const rules = questions.flatMap((q) => getRules(state, q.rules))
      const screens = getScreens(state, advisor.id)

      const externalAdvisor = Advisor.internalToExternal(
        advisor,
        questions,
        answers,
        rules,
        screens!,
        state.flow[advisorId]
      )

      return advisors
        .update(
          externalAdvisor,
          opts.context.token,
          opts.context.organisationId
        )
        .then((externalAdvisor) => {
          const { advisor, questions, answers, rules } =
            Advisor.externalToInternalExpanded(externalAdvisor)
          dispatch(
            updateAdvisorSuccess(advisor, questions, rules, answers, screens!)
          )
          opts.finishCallback()
        })
        .catch((error) => {
          console.error(error)
          dispatch(updateAdvisorFailure(advisorId))
          notifyIfPermissionChanged(error, dispatch)
        })
        .finally(() => {
          opts.finishCallback()
        })
    } else {
      opts.finishCallback()
    }
  }

function notifyIfPermissionChanged(error: any, dispatch: any) {
  if (error?.status === 422 && !!error.data.payload["customer_info"]) {
    const notificationAction = notify(
      {
        text: "Something went wrong, please reload the page and try again.",
        type: "error",
      },
      6000
    )

    // @ts-ignore
    notificationAction(dispatch)
  }
}
