<svelte:options customElement="aiden-customer-info-form" />

<script lang="ts">
  import Plane from "../ui/Plane.svelte"
  import CustomerInfoForm from "../app/components/customerInfo/CustomerInfoForm.svelte"
  import { createFormState } from "../app/components/customerInfo/CustomerInfoFormStore"

  const state = createFormState(() => {})
  export let checked: string = "false"

  $:{
    $state.checkboxChecked = checked === "true"
    $state.emailValid = true
    $state.email = "example@email.com"
  }
</script>

<div>
  <Plane>
    <CustomerInfoForm
      formConfig={{
        enabled: true,
        showCheckbox: true,
        checkboxLabel: "Sign up for the newsletter",
        formSubmittedMessage: "",
      }}
      withSubmitButton
      formState={state}
    />
  </Plane>
</div>

<style>
  div :global(*) {
    cursor: default !important;
  }
  div {
    /* --aiden-regular-text-color: var(--aiden-); */
  }
  div{
    pointer-events: none;
  }
</style>
