import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"

export const StyleSheetCSSVariables: React.FC<{
  styleTemplateId: string
}> = ({ styleTemplateId }) => {
  const { token: authToken, organisationId } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string

  return (
    <Elm
      src={ElmModule.StyleSheetCSSVariables}
      flags={{
        authToken: authToken,
        organisationId: organisationId,
        apiUrl: apiUrl,
        styleTemplateId: styleTemplateId,
      }}
      ports={() => {}}
    />
  )
}
