<script lang="ts">
  import { t } from "../app/lang/i18n"
  import type { PredefinedOption } from "../app/components/page/toPage"
  import AnswerOption from "./AnswerOption.svelte"
  import Layout from "./Layout.svelte"
  import Bounded from "./Bounded.svelte"
  import Markdown from "./Markdown.svelte"
  import Image from "../app/components/media/Image.svelte"
  import FieldsetWithLegend from "./FieldsetWithLegend.svelte"

  export let options: PredefinedOption[]
  export let type: "single" | "multi"
  export let isSelected: (option: PredefinedOption) => boolean = () => false
  export let onSelect: (option: PredefinedOption) => void = () => {}
  export let neutralAnswerValue: string | undefined = undefined

  let hasImages = options.some((opt) => opt.image)
  $: hasImages = options.some((opt) => opt.image)

  const isMultipleAnswers = type === "multi"
  const hasNeutralAnswerValue = Boolean(neutralAnswerValue)

  const legendText = !isMultipleAnswers
    ? $t("singleAnswerQuestionLegend")
    : isMultipleAnswers && hasNeutralAnswerValue
      ? $t("multiAnswerQuestionWithNeutralLegend")
      : $t("multiAnswerQuestionLegend")
</script>

<Bounded unbound={true}>
  <FieldsetWithLegend legend={legendText}>
    <Layout type={hasImages ? "grid" : "list"}>
      {#each options as option}
        <AnswerOption
          {option}
          {type}
          onClick={() => onSelect(option)}
          isSelected={isSelected(option)}
        >
          <span slot="image">
            {#if option.image}
              <Image alt="" src={option.image} />
            {/if}
          </span>

          {#if option.helpText}
            <Markdown source={option.helpText} allowed="default" />
          {/if}
        </AnswerOption>
      {/each}
    </Layout>
  </FieldsetWithLegend>
</Bounded>
