<script lang="ts">
  import BadMatch from "../icons/BadMatch.svelte"
  import Good from "../icons/Good.svelte"
  import Perfect from "../icons/Perfect.svelte"
  import { t } from "../../app/lang/i18n"
  import type { Traceability } from "../../app/types"

  export let features: Traceability[] = []
  export let responsive: boolean = false
  export let horizontal: boolean = true

  const ariaLabel = ({ answer, score }: Traceability) => {
    switch (score) {
      case "good":
        return `${$t("goodMatchLabel")}: ${answer}`
      case "bad":
        return `${$t("badMatchLabel")}: ${answer}`
      case "perfect":
        return `${$t("perfectMatchLabel")}: ${answer}`
    }
  }
</script>

<div class="product-features">
  <ul
    aria-label={$t("productFeaturesLabel")}
    class="product-features-list"
    class:horizontal
    class:responsive
  >
    {#each features as feature}
      {#if feature.score !== "neutral"}
        <li
          aria-label={ariaLabel(feature)}
          class:responsive
          class="product-feature"
        >
          <span
            aria-hidden="true"
            class="icon-container"
            class:bad-match={feature.score === "bad"}
            class:good-match={feature.score === "good" ||
              feature.score === "perfect"}
          >
            {#if feature.score == "bad"}
              <BadMatch aria-hidden="true" />
            {:else if feature.score == "good"}
              <Good aria-hidden="true" />
            {:else if feature.score == "perfect"}
              <Perfect aria-hidden="true" />
            {/if}
          </span>
          <span class="answer">{feature.answer}</span>
        </li>
      {/if}
    {/each}
  </ul>
</div>

<style>
  .product-features {
    grid-column: 1/-1;
  }

  .product-features-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.75rem;
    display: grid;
    gap: 0.125rem 0;

    &.horizontal {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.125rem 0.5rem;
    }
  }

  .product-feature {
    display: flex;
    align-items: start;

    & .answer {
      padding-left: 2px;
      padding-top: 0.1875rem;
      word-break: break-word;
      min-height: 1.5rem;
    }

    & .icon-container {
      height: 1.5rem;
      display: inline-block;
      vertical-align: middle;

      &.bad-match {
        color: #c3c3c3;
        width: 1.5rem;
        display: flex;
        justify-content: center;
      }

      &.good-match {
        color: #00ad00;
      }
    }

    &.responsive {
      display: flex;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: 721px) {
    .product-features {
      margin-top: 0;
    }
  }
</style>
