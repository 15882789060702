import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostra altro",
  showLess: "Mostra meno",
  bestMatch: "Miglior match",
  view: "Guarda",
  customAction: "",
  notFound: "404: non trovato",
  reset: "Ricominciare",
  close: "Chiudere",
  noAdviceTitle:
    "Al momento non ci sono prodotti disponibili che corrispondono pienamente alle tue preferenze.",
  noAdviceMessage: "Regola le tue preferenze o riprova più tardi.",
  prev: "Precedente",
  next: "Prossimo",
  backToAdvice: "Torna ai consigli",
  dontKnow: "Saltare",
  email: "Indirizzo e-mail",
  send: "Inviare",
  emailError: "Inserisci un indirizzo email valido",
  readMore: "Leggi di più",
  selectAnAnswer: "Seleziona una risposta",
  answerNotListed: "La mia risposta non è elencata",
  whyThisProductForMe: "Perché è questo per me?",
  alternative: "alternativa",
  alternatives: "alternative",
  seeAlso: "Guarda anche",
  productFeaturesLabel: "In che misura questo corrisponde alle tue preferenze?",
  badMatchLabel: "Corrispondenza negativa",
  goodMatchLabel: "Buona corrispondenza",
  perfectMatchLabel: "Corrispondenza perfetta",
  singleAnswerQuestionLegend: "Scegli un'opzione e continua.",
  multiAnswerQuestionLegend: "Scegli una o più opzioni.",
  multiAnswerQuestionWithNeutralLegend: "Scegli una o più opzioni oppure l'opzione in basso se non hai preferenze.",
  manualDrilldownLegend: "Seleziona un'opzione. Se è presente una domanda successiva, verrà visualizzata dopo aver scelto una risposta. Utilizza il pulsante 'Prossimo' per continuare dopo aver risposto a tutte le domande.",
  autoDrilldownLegend: "Seleziona un'opzione. Se è presente una domanda successiva, verrà visualizzata dopo aver scelto una risposta. Utilizza il pulsante 'Prossimo' per continuare dopo aver risposto a tutte le domande. La tua risposta non è elencata? Quindi seleziona 'La mia risposta non è elencata'.",
  loading: "Caricamento",
  progress: "Progressi",
  numberInputDefaultLabel: "Inserisci un numero.",
  numberInputWithUnitLabel: "Inserisci un numero in {{unit}}.",
  actionSucceededLabel: "Riuscito",
  actionFailedLabel: "Fallito",
  reviewScoreLabel: "Questo prodotto è valutato: {{score}} stelle su 5 in base a {{count}} recensioni.",
  energyLabel: "Questo prodotto ha l'etichetta energetica: {{score}} sulla scala di: {{scale}}.",
  previousPriceLabel: "Questo prodotto aveva in precedenza un prezzo di {{price}}.",
  actualPriceLabel: "La descrizione del prezzo attuale di questo prodotto è: {{prefix}} {{price}} {{suffix}}.",
  productFieldsLabel: "Altre informazioni",
  closeLabel: "Chiudi il localizzatore prodotti",
  resetLabel: "Riavvia il localizzatore prodotti",
  prevLabel: "Vai al passaggio precedente",
  nextLabel: "Vai al passaggio successivo",
}

export default translations
