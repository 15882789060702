export const TITLE_MAX_LENGTH = 255
export const DEFAULT_TEXT_LENGTH = 255
export const INTRO_MAX_LENGTH = 700
export const ADDITIONAL_INFO_MAX_LENGTH = 700
export const CUSTOMER_INFO_TITLE_MAX_LENGTH = 700
export const LABEL_MAX_LENGTH = 64
export const BUTTON_MAX_LENGTH = 64
export const CTA_BUTTON_MAX_LENGTH = 70
export const FORM_IDENTIFIER_MAX_LENGTH = 70
export const FORM_SUBMIT_FEEDBACK_MAX_LENGTH = 700
export const PREFIX_SUFFIX_MAX_LENGTH = 150
export const PRICE_PREFIX_SUFFIX_MAX_LENGTH = 16
