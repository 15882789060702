<script lang="ts">
  import type { CustomerInfoFormConfig } from "../../types"
  import Button from "../../../ui/Button.svelte"
  import { t } from "../../lang/i18n"
  import { onDestroy } from "svelte"
  import { type FormStateStore } from "./CustomerInfoFormStore"
  import Markdown from "../../../ui/Markdown.svelte"
  import CheckBox from "../../../ui/CheckBox.svelte"
  import RegularText from "../../../ui/RegularText.svelte"
  import SmallText from "../../../ui/SmallText.svelte"

  export let formConfig: CustomerInfoFormConfig
  export let resetAfterSubmit: boolean = false
  export let onResetAfterSubmit: () => void = () => {}
  export let withSubmitButton = true
  export let formState: FormStateStore

  let resetTimeout: any
  let displayConfirmation: boolean = false

  onDestroy(() => clearTimeout(resetTimeout))
  $: {
    if (resetAfterSubmit && displayConfirmation) {
      resetTimeout = setTimeout(() => {
        onResetAfterSubmit()
      }, 8_000)
    }
  }

  function onSubmit() {
    const submitted = formState.submit()

    if (submitted) {
      if (withSubmitButton) {
        displayConfirmation = true
      }
    }
  }
</script>

<div
  aria-live="polite"
  role="group"
  aria-labelledby="email-form-title"
  aria-label={$t("email")}
  aria-describedby="email-form-intro"
>
  {#if displayConfirmation}
    <RegularText role="status">
      <Markdown allowed="default" source={formConfig.formSubmittedMessage} />
    </RegularText>
  {:else}
    <form class="email-form" novalidate on:submit|preventDefault={onSubmit}>
      <slot />
      <div class="email-form__group">
        <div class="email-form__field">
          <label for="email" class="visually-hidden">{$t("email")}</label>
          <input
            type="email"
            name="email"
            id="email"
            autocomplete="email"
            placeholder={$t("email")}
            aria-required={true}
            aria-invalid={$formState.displayEmailError}
            required={true}
            on:input={(event) => {
              if (event.target) {
                //@ts-ignore
                formState.setEmail(
                  event.currentTarget.value,
                  //@ts-ignore
                  event.target.validity.valid
                )
              }
            }}
            minlength="7"
            maxlength="60"
            data-lpignore="true"
            data-1p-ignore="true"
          />
          <span role="alert" class="email-error">
            {#if $formState.displayEmailError}
              {$t("emailError")}
            {/if}
          </span>
        </div>
        {#if formConfig.showCheckbox}
          <label class="checkbox">
            <input
              type="checkbox"
              bind:checked={$formState.checkboxChecked}
              name="checkbox"
              class="visually-hidden"
            />
            <CheckBox checked={$formState.checkboxChecked} />
            <span class="label-text">
              <SmallText>
                <Markdown
                  allowed="links-only"
                  source={formConfig.checkboxLabel}
                />
              </SmallText>
            </span>
          </label>
        {/if}
        {#if withSubmitButton}
          <Button
            variant="primary"
            className="side-button"
            testId="email-form-submit"
            config={{ disabled: !$formState.email }}
          >
            {$t("send")}
          </Button>
        {/if}
      </div>
    </form>
  {/if}
</div>

<style>
  .email-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0rem auto;
  }

  /* .email-form:not(.alignLeft) :global(h1),
  .email-form:not(.alignLeft) :global(p) {
    text-align: center;
  } */

  .email-form__group {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .email-form__group :global(button) {
    margin-top: 0.75em;
    margin-left: auto;
    grid-area: submit;
    align-self: start;
  }

  .email-form__field {
    display: flex;
    flex-direction: column;
    grid-area: email;
  }

  .email-form__field input {
    color: var(--aiden-input-font-color);
    padding: 0.5em;
    /* height: 100%; */
    height: var(--aiden-input-height, 3.5rem);
    width: 100%;
    font-size: 1em;
    background: transparent;
    -moz-appearance: textfield;
    appearance: textfield;
    box-sizing: border-box;
    font-family: var(--aiden-regular-text-font, inherit);
    border: var(--aiden-input-border-width, 1px) solid
      var(--aiden-input-border-color, #ebebeb);
    background: var(--aiden-input-background-color, #fff);
    border-radius: var(--aiden-input-border-radius, 0.5rem);
    padding: var(--aiden-primary-button-vertical-padding, 0.5rem)
      var(--aiden-primary-button-horizontal-padding, 1rem);
  }
  input:focus-visible,
  input:focus {
    border-color: var(--aiden-input-border-color-focus, #000);
  }

  .email-error {
    color: #ee0202;
    line-height: 2;
    font-weight: bold;
    font-size: 0.875em;
  }

  .checkbox {
    margin-top: 0.75em;
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 0.875em !important;
    cursor: pointer;
    grid-area: checkbox;

    & input:focus-visible + div {
      outline: Highlight auto 2px;
      outline: -webkit-focus-ring-color auto 2px;
    }
  }

  .checkbox .label-text {
    margin-left: 0.5em;
    margin-top: 0.125em;
    word-break: break-word;
    white-space: pre-wrap;
    color: var(--aiden-regular-text-color, #000);
    font-weight: var(--aiden-regular-text-font-weight, 400);
    font-family: var(--aiden-regular-text-font, inherit);
    font-size: clamp(0.75rem, var(--aiden-regular-text-font-size, 1rem), 2rem);
  }
  .visually-hidden {
    position: absolute;
    display: block;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }
  :global(.side-button) {
    height: var(--aiden-input-height, 3.5rem) !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    box-sizing: border-box;
  }

  @media screen and (min-width: 640px) {
    .email-form__group {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-auto-rows: min-content min-content;
      gap: 0 1rem;
      grid-template-areas:
        "email submit"
        "checkbox checkbox";
    }

    .email-form__group :global(button) {
      margin: 0;
    }
  }
</style>
