<script lang="ts">
  import { type ProductInContextClick } from "../../app/components/recommendation/productClickHandler"
  import ContextMenuHandler from "../../helpers/contextMenuHandler"
  import Button from "../Button.svelte"
  export let href: string | undefined = undefined

  export let onClick: ProductInContextClick | undefined = undefined

  export let target: "_blank" | "self" | "_parent" = "_parent"
  export let testId: string | undefined = ""
</script>

{#if href}
  <Button
    config={{
      href,
      target,
    }}
    isFullWidth
    variant="primary"
    {testId}
    {ContextMenuHandler}
    onContextMenu={() =>
      onClick && onClick(undefined, "PRODUCT_CTA", "LINK_RIGHT_CLICK")}
    onClick={(e) => onClick && onClick(e, "PRODUCT_CTA", "LINK")}
  >
    <slot />
  </Button>
{:else}
  <Button isFullWidth variant="primary">
    <slot />
  </Button>
{/if}
