import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mehr zeigen",
  showLess: "Weniger anzeigen",
  bestMatch: "Bester Treffer",
  view: "Siehe",
  customAction: "",
  notFound: "404: nicht gefunden",
  reset: "Neu anfangen",
  close: "Schließen",
  noAdviceTitle:
    "Derzeit haben wir keine Produkte verfügbar, die vollständig Ihren Vorlieben entsprechen.",
  noAdviceMessage:
    "Bitte passen Sie Ihre Präferenzen an, oder versuchen Sie es später noch einmal.",
  prev: "Vorherige",
  next: "Weiter",
  backToAdvice: "Zurück zur Beratung",
  dontKnow: "Überspringen",
  email: "E-Mail-Adresse",
  send: "Senden",
  emailError: "Geben sie eine gültige E-Mail-Adresse an",
  readMore: "Weiter lesen",
  selectAnAnswer: "Wählen Sie eine Antwort aus",
  answerNotListed: "Meine Antwort ist nicht aufgeführt",
  whyThisProductForMe: "Warum dieser Rat?",
  alternative: "Alternative",
  alternatives: "Alternativen",
  seeAlso: "Auch anschauen",
  productFeaturesLabel: "Wie passt das zu deinen Vorlieben?",
  badMatchLabel: "Schlechte Übereinstimmung",
  goodMatchLabel: "Gute Übereinstimmung",
  perfectMatchLabel: "Perfekte Übereinstimmung",
  singleAnswerQuestionLegend: "Wählen Sie eine Option und fahren Sie fort.",
  multiAnswerQuestionLegend: "Wählen Sie eine oder mehrere Optionen.",
  multiAnswerQuestionWithNeutralLegend: "Wählen Sie eine oder mehrere Optionen oder die unterste Option, wenn Sie keine Präferenz haben.",
  manualDrilldownLegend: "Wählen Sie eine Option aus. Wenn es eine Folgefrage gibt, wird diese nach Auswahl einer Antwort angezeigt. Nachdem Sie alle Fragen beantwortet haben, können Sie über die Schaltfläche „Weiter“ fortfahren.",
  autoDrilldownLegend: "Wählen Sie eine Option aus. Wenn es eine Folgefrage gibt, wird diese nach Auswahl einer Antwort angezeigt. Nachdem Sie alle Fragen beantwortet haben, können Sie über die Schaltfläche „Weiter“ fortfahren. Ist Ihre Antwort nicht aufgeführt? Wählen Sie dann „Meine Antwort ist nicht aufgeführt“.",
  loading: "Wird geladen",
  progress: "Fortschritt",
  numberInputDefaultLabel: "Geben Sie eine Zahl ein.",
  numberInputWithUnitLabel: "Geben Sie eine Zahl in {{unit}} ein.",
  actionSucceededLabel: "Erfolgreich",
  actionFailedLabel: "Fehlgeschlagen",
  reviewScoreLabel: "Dieses Produkt hat die Bewertung: {{score}} von 5 Sternen basierend auf {{count}} Rezensionen.",
  energyLabel: "Dieses Produkt hat das Energielabel: {{score}} auf der Skala von: {{scale}}.",
  previousPriceLabel: "Der Preis für dieses Produkt betrug zuvor {{price}}.",
  actualPriceLabel: "Die aktuelle Preisbeschreibung dieses Produkts lautet: {{prefix}} {{price}} {{suffix}}.",
  productFieldsLabel: "Weitere Informationen",
  closeLabel: "Produktfinder schließen",
  resetLabel: "Starten Sie den Produktfinder neu",
  prevLabel: "Zum vorherigen Schritt gehen",
  nextLabel: "Weiter zum nächsten Schritt",
}

export default translations
