export default function focusTrap(element: HTMLElement) {
  if (element) {
    element.focus()

    const focusableElements =
      "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"

    document.addEventListener("keydown", function (e) {
      const isTabPressed = e.key === "Tab" // Tab keyCode

      if (isTabPressed) {
        const focusableContent: NodeListOf<HTMLElement> =
          element.querySelectorAll(focusableElements)
        const firstFocusableElement = focusableContent[0]
        const lastFocusableElement =
          focusableContent[focusableContent.length - 1] // get last element to be focused inside modal

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus() // add focus for the last focusable element
            e.preventDefault()
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus() // add focus for the first focusable element
            e.preventDefault()
          }
        }
      }
    })
  }
}
