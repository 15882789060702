import type { ProductField } from "../../../app/types"

const stripTags = (value: string) => {
  const html = new DOMParser().parseFromString(value, "text/html")
  const text = html.body.textContent || ""
  return text
}

export function removeHTMLMarkup(
  fields: ProductField[]
): { values: string; label: string, suffix: string }[] {
  return fields
    .map((f) => {
      return {
        ...f,
        values: f.values
          .filter((v) => v.trim() !== "")
          .map((v) => (stripTags(v)).trim())
          .join(", "),
      }
    })
    .filter((f) => f.values.length > 0)
}
