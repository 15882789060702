const DIGITS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

export const numericInputSanitizer = (
  allowsDecimals: boolean,
  separator: string
) => {
  const containsSeparator = (value: string): boolean =>
    value.includes(",") || value.includes(".")

  const isSeparator = (value: string): boolean => value === "," || value === "."

  const allowDigit = (c: string, value: string): string => {
    if (DIGITS.includes(c)) {
      return c + value
    } else {
      return value
    }
  }

  const allowDigitOrSeparator = (c: string, value: string): string => {
    if (DIGITS.includes(c)) {
      return c + value
    } else if (isSeparator(c)) {
      return separator + value
    } else {
      return value
    }
  }

  const forceFloat = (value: string, c: string): string => {
    if (allowsDecimals) {
      if (containsSeparator(value)) {
        return allowDigit(c, value)
      } else {
        return allowDigitOrSeparator(c, value)
      }
    } else {
      return allowDigit(c, value)
    }
  }

  const forceFloatValue = (value: string): string =>
    value.split("").reverse().reduce(forceFloat, "")

  return {
    sanitizeInput: forceFloatValue,
  }
}
