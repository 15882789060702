import React from "react"
import { Advisor as AdvisorProps } from "~/store/advisors"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import QandA from "./QandA"
import Products from "./Products"
import Dashboard from "./Dashboard"
import Mapping from "./Products/Advice"
import PageContainer from "~/components/PageContainer"
import { QandASidebarProvider } from "./QandA/QandASidebarContext"
import LookAndFeel from "./LookAndFeel"
import Settings from "~/components/advisors/Settings"
// import Analyze from "~/components/advisors/Dashboard/Analyze"
import Analyze from "~/components/advisors/Dashboard/Analyze"
import Integrate from "~/components/advisors/Dashboard/Integrate"
import Page from "./Page"
import Publish from "./Publish"
import CreateCatalogue from "./Products/Catalogue/CreateCatalogue"

const Advisor: React.FC<{
  advisor: AdvisorProps
}> = ({ advisor }) => {
  const { url } = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${url}/catalogue/create`}
        render={(p) => <CreateCatalogue advisor={advisor} {...p} />}
      />
      <Route
        path={`${url}/catalogue`}
        render={(p) => <Products advisor={advisor} {...p} />}
      />
      <Route
        path={`${url}/advice`}
        render={(p) => <Mapping advisor={advisor} />}
      />
      <Route
        path={`${url}/flow`}
        render={(p) => (
          <QandASidebarProvider>
            <QandA advisor={advisor} {...p} />
          </QandASidebarProvider>
        )}
      />

      <Route path={`${url}/look-and-feel`}>
        <Redirect to={`${url}`} />
      </Route>
      <Route
        path={`${url}/insights`}
        render={(p) => (
          <PageContainer>
            <h1>Insights</h1>
          </PageContainer>
        )}
      />

      <Route
        path={`${url}/settings`}
        render={(p) => (
          <Page>
            <Settings advisor={advisor} {...p} />
          </Page>
        )}
      />
      <Route
        path={`${url}/integrate`}
        render={(p) => (
          <Page maxWidth>
            <Integrate advisor={advisor} />
          </Page>
        )}
      />
      <Route
        path={`${url}/publish`}
        render={(p) => (
          <Page maxWidth>
            <Publish advisor={advisor} />
          </Page>
        )}
      />
      <Route
        path={`${url}/analyze`}
        render={(p) => <Analyze advisor={advisor} />}
      />

      <Route
        path={`${url}/`}
        render={(p) => <Dashboard advisor={advisor} {...p} />}
      />
    </Switch>
  )
}

export default Advisor
