<script lang="ts">
  import { t } from "../app/lang/i18n"
  import Chevron from "./Chevron.svelte"
  export let id: string = ""
  export let label: string
  export let value: string
  export let options: { title: string }[] = []
  export let onSelect: (value: string) => void = () => {}
  export let onBlur: (value: string) => void = () => {}

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  })

  $: sortedOptions = options
    .map((opt) => opt.title)
    .sort((a, b) => collator.compare(a, b))

  $: isRequired = Boolean(options.length)
</script>

<div>
  {#if label.trim() !== ""}
    <label for={id}>{label}</label>
  {/if}
  <select
    name={id}
    {id}
    aria-required={isRequired}
    required={isRequired}
    on:change={(e) => onSelect(e.currentTarget.value)}
    on:blur={(e) => onBlur(e.currentTarget.value)}
  >
    {#if value === ""}
      <option selected={true} disabled={true} value={""}>
        {$t("selectAnAnswer")}
      </option>
    {/if}
    {#each sortedOptions as answerOption}
      <option value={answerOption} selected={answerOption === value}>
        {answerOption}
      </option>
    {/each}
  </select>
  <span class="chevron">
    <Chevron />
  </span>
</div>

<style>
  label {
    font-family: var(--aiden-input-label-font-family, "system-ui");
    font-weight: var(--aiden-input-label-font-weight, 600);
    color: var(--aiden-input-label-color, #000);
    display: inline-block;
    margin-bottom: 0.25rem;
  }
  select {
    all: revert;
    font-size: 1rem;
    font-family: var(--aiden-input-font-family, inherit);
    border-radius: var(--aiden-input-border-radius, 0.5rem);
    color: var(--aiden-input-font-color, #000);
    height: var(--aiden-input-height, 3.5rem);


    width: 100%;
    padding: 0.25rem 2rem 0.25rem 0.5rem;
    border: var(--aiden-input-border-width, 1px) solid
      var(--aiden-input-border-color, #ebebeb);
    transition: all 150ms ease-out;
    background: transparent;
    -webkit-appearance: none;
    appearance: none;
    line-height: 2.5rem;
    cursor: pointer;
    background-color: var(--aiden-input-background-color, #fff);
    font-weight: var(--aiden-input-font-weight, 400);
  }
  select:focus-visible,
  select:hover {
    outline: none;
    border-color: var(--aiden-input-border-color-focus, #000);
  }
  div {
    position: relative;
  }
  .chevron {
    position: absolute;
    display: block;
    top: calc(50%);
    right: 1rem;
    z-index: 1;
    width: 24px;
    width: 24px;
    transform: rotate(90deg);

    color: var(--aiden-drilldown-select-chevron-color, #999);
  }
</style>
