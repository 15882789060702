<script lang="ts">
  import Page from "../../app/components/page/Page.svelte"
  import type { Page as PageT } from "../../app/components/page/toPage"

  export let page: PageT = {
    type: "start-page",
    screenId: "string",
    title: "Start page title",
    text: "Start page intro text. Ipsum Mattis Nibh Euismod Tortor [with a link.]()",
    additionalText: "",
    buttonText: "Start",
    legacyShowOnlyButton: false,
  }
</script>

<Page
  {page}
  isPreview
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
  hidePrev
></Page>
