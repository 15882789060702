<svelte:options customElement="aiden-screen-preview-v2" />

<!--
  @component

  Displays a visual preview of a screen. The json passed should contain a valid Screen format.
  Style variables and fonts should be loaded separately using the LookAndFeel.StyleSheet Elm module.
-->
<script lang="ts">
  import Page from "../app/components/page/Page.svelte"
  import { i18n } from "../app/lang/i18n"
  

  export let preview: string
  export let locale: string

  $: {
    i18n.setupI18n({ withLocale: locale })
  }

  const parsedPreview = JSON.parse(preview)
  let page = parsedPreview

  $: page = JSON.parse(preview)
</script>

<div
  class="wrapper"
  class:is-advice-page={page?.type === "advice-page" ||
    page?.type === "product-check-advice-page"}
  class:is-empty-advice-page={page?.type === "empty-advice-page"}
  class:is-start-page={page?.type === "start-page"}
>
  <div class="page">
    <div class="sidebar-content-wrapper">
      {#key page}
        <Page
          {page}
          setAttribute={() => {}}
          getAttribute={() => null}
          optionsFor={() => Promise.resolve([])}
          hidePrev={false}
          hideNext={true}
          isPreview={true}
        />
      {/key}
    </div>
  </div>
</div>

<style>
  .wrapper {
    --frame-padding: 1rem;
    pointer-events: none;
  }
  @media screen and (min-width: 640px) {
    .wrapper {
      --frame-padding: 2rem;
    }
  }
  .wrapper {
    height: 100%;
  }
  .page {
    min-height: 100%;
  }

  .page {
    --plane-color: var(--aiden-app-background-color, #fff);
    background-color: var(--plane-color);
  }
  .is-advice-page .page,
  .is-empty-advice-page .page {
    --plane-color: var(--aiden-advice-page-header-background-color, #fff);
    --aiden-top-navigation-background-color: var(
      --aiden-advice-page-header-background-color,
      #fff
    );
    background-color: var(--plane-color);
  }

  .is-empty-advice-page .sidebar-content-wrapper,
  .is-advice-page .sidebar-content-wrapper {
    background-color: var(--aiden-advice-page-bottom-background-color,);
    height: 100%;
  }

  .is-start-page .page {
    --plane-color: var(--aiden-start-page-background-color, #fff);
    --aiden-app-background-color: var(--plane-color);
    background-color: var(--plane-color);
    --aiden-navigation-button-color: var(
      --aiden-start-page-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-start-page-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-start-page-navigation-button-color-hover
    );
  }

  .is-advice-page,
  .is-empty-advice-page {
    --aiden-navigation-button-color: var(
      --aiden-advice-page-top-navigation-button-color
    );

    --aiden-navigation-button-color-hover: var(
      --aiden-advice-page-top-navigation-button-color-hover
    );
    --aiden-navigation-button-color: var(
      --aiden-advice-page-top-navigation-button-color
    );
  }

  :global(.screenreader-only) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap;
    border: 0;
    opacity: 0;
  }
</style>
