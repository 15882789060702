<script lang="ts">
  import EmbedInterop from "./interop/EmbedInterop"
  import modalCloseOnEscape from "./interop/closeOnEscape"
  import modalFocusTrap from "./interop/focusTrap"

  let container: HTMLElement

  const modalCloseId = "aiden-modal-close-button"

  export let advisorId: string

  const sendBackgroundClick = (event: KeyboardEvent | MouseEvent) => {
    const target = event.target as Element
    if (container === target || target.id === modalCloseId) {
      event.stopPropagation()
      EmbedInterop.sendBackgroundClickEvent(advisorId)
    }
  }

  $: modalCloseOnEscape(modalCloseId)
  $: modalFocusTrap(container)
</script>

<div tabindex="-1" bind:this={container} on:click={sendBackgroundClick}>
  <!-- svelte-ignore a11y-invalid-attribute -->
  <slot />
  <!-- <Button
    isCircle
    isSmall
    onClick={sendBackgroundClick}
    id={modalCloseId}
    testId={modalCloseId}
  >
    <Cross />
  </Button> -->
</div>

<style>
  div {
    padding: 0.5rem 0.5rem;
    min-height: 100vh;
    box-sizing: border-box;
  }

  :global(#aiden-modal-close-button) {
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 640px) {
    :global(#aiden-modal-close-button) {
      top: 0.75rem;
    }
  }

  @media screen and (min-width: 74rem) {
    div {
      padding: 4.5rem 0.5rem;
    }
    :global(#aiden-modal-close-button) {
      right: calc(((100% - 64rem) / 2) - 4rem);
    }
  }
</style>
