<script lang="ts">
  import { navigationContext } from "../../svelte-stores/navigationStore"
  import BottomNavigation from "../../../ui/BottomNavigation.svelte"
  import {
    type FormStateStore,
    createFormState,
  } from "../customerInfo/CustomerInfoFormStore"
  import CustomerInfoForm from "../customerInfo/CustomerInfoForm.svelte"
  import type { InfoPage } from "./toPage"
  import EmbeddedVideo from "../media/EmbeddedVideo.svelte"
  import Image from "../media/Image.svelte"
  import Bounded from "../../../ui/Bounded.svelte"
  import TopHeader from "../../../ui/TopHeader.svelte"
  import Plane from "../../../ui/Plane.svelte"
  import { appAnalyticsStore } from "../../../analytics/appAnalytics"

  export let page: InfoPage

  const formIdentifier = page.customerInfoForm.formIdentifier

  const formState: FormStateStore = createFormState(
    (email, checkboxChecked) => {
      $appAnalyticsStore.customerInformationFormSubmittedEvent(
        { type: "INFO", id: formIdentifier },
        email,
        {
          checked: checkboxChecked,
          checkboxText: page.customerInfoForm.checkboxLabel,
        }
      )

      $navigationContext.hasNextScreen && $navigationContext.next()
    }
  )
</script>

<Bounded>
  <TopHeader
    title={page.title}
    text={page.text}
    smallText={page.additionalText}
  >
    {#if page.media?.type === "image"}
      <Image src={page.media.src} alt="" />
    {:else if page.media?.type === "video"}
      <EmbeddedVideo>
        {@html page.media.src}
      </EmbeddedVideo>
    {/if}
  </TopHeader>
  {#if page.customerInfoForm.enabled}
    <Plane>
      <CustomerInfoForm
        formConfig={page.customerInfoForm}
        withSubmitButton={!$navigationContext.hasNextScreen}
        {formState}
      />
    </Plane>
  {/if}
</Bounded>
<BottomNavigation
  onPrev={$navigationContext.prev}
  hideNext={!$navigationContext.hasNextScreen}
  onNext={() => {
    if ($formState.email) {
      formState.submit() && $navigationContext.next()
    } else {
      $navigationContext.next()
    }
  }}
/>
