import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { ScreenType } from "~/store/screens"
import { getScreens } from "~/store/screens/selectors"
import { PreviewSidebar } from "./PreviewSidebar"
import useMedia from "~/hooks/useMedia"
import { Advisor } from "~/store/advisors"
import {
  adviceScreenToPage,
  emptyAdviceScreenToPage,
  infoScreenToPage,
  introScreenToPage,
} from "./advisorModelToPagePreview"

export const ScreenPreview: React.FC<{
  advisor: Advisor
  screenId: string
}> = ({ advisor, screenId }) => {
  const screenToPreview = useSelector((state: ApplicationState) => {
    const screens_ = getScreens(state, advisor.id)

    if (screens_?.advice?.id === screenId) return screens_.advice
    if (screens_?.intro?.id === screenId) return screens_.intro
    return screens_?.infoScreens.find((s) => s.id === screenId)
  })
  const mediaType =
    screenToPreview?.type === ScreenType.INFO ? screenToPreview.mediaType : ""
  const media = useMedia({ type: "screen", id: screenId }, mediaType)
  const isAdviceScreen = screenToPreview?.type === "ADVICE"
  const [advicPageType, setAdvicePageType] = useState<
    "ADVICE" | "EMPTY_ADVICE"
  >("ADVICE")

  const page =
    screenToPreview?.type === "ADVICE" && advicPageType === "ADVICE"
      ? adviceScreenToPage(screenToPreview, advisor.customerInfo)
      : screenToPreview?.type === "ADVICE" && advicPageType === "EMPTY_ADVICE"
      ? emptyAdviceScreenToPage(screenToPreview)
      : screenToPreview?.type === "INTRO"
      ? introScreenToPage(screenToPreview)
      : screenToPreview?.type === "INFO"
      ? infoScreenToPage(screenToPreview, media)
      : undefined

  if (!page) return null
  return (
    <PreviewSidebar preview={page}>
      {isAdviceScreen && (
        <div className="mr-auto ml-auto" style={{"width": "min-content"}}>
          <div className="ui-toggle-group mt-xs mb-xs">
            <button
              className={`ui-toggle-btn ${
                advicPageType === "ADVICE" ? "--is-active" : ""
              }`}
              onClick={() => setAdvicePageType("ADVICE")}
            >
              Advice
            </button>
            <button
              className={`ui-toggle-btn ${
                advicPageType === "EMPTY_ADVICE" ? "--is-active" : ""
              }`}
              onClick={() => setAdvicePageType("EMPTY_ADVICE")}
            >
              Empty Advice
            </button>
          </div>
        </div>
      )}
    </PreviewSidebar>
  )
}
