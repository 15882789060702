import type { GetAttribute, SetAttribute } from "../types"
import type { PredefinedOption } from "../components/page/toPage"

function getNumericAttribute(
  getAttribute: GetAttribute
): (attributeInstanceId: string) => number | null {
  return (attributeInstanceId) => {
    const attr = getAttribute(attributeInstanceId)
    if (attr === null) return null
    if (typeof attr === "number") return attr
    return null
  }
}

export type AutoDrilldownAnswer =
  | { type: "neutral-answer"; value: string }
  | {
      type: "answer"
      value: string[]
    }

function getAutoDrilldownAttribute(
  getAttribute: GetAttribute
): (attributeInstanceId: string) => AutoDrilldownAnswer {
  return (attributeInstanceId) => {
    const attr = getAttribute(attributeInstanceId)
    if (Array.isArray(attr)) return { type: "answer", value: attr }
    if (typeof attr === "string") return { type: "neutral-answer", value: attr }
    return { type: "answer", value: [] }
  }
}

function getSingleAttribute(
  getAttribute: GetAttribute
): (attributeInstanceId: string) => string | null {
  return (attributeInstanceId) => {
    const attr = getAttribute(attributeInstanceId)
    if (attr === null) return null
    if (typeof attr === "string") return attr
    return null
  }
}

function getListAttribute(
  getAttribute: GetAttribute
): (attributeInstanceId: string) => string[] {
  return (attributeInstanceId) => {
    const attr = getAttribute(attributeInstanceId)
    if (Array.isArray(attr)) {
      return attr as string[]
    }
    if (typeof attr === "string") {
      return [attr]
    }
    return []
  }
}

function isMultiSelected(
  getAttribute: GetAttribute,
  attributeInstanceId: string,
  option: PredefinedOption
): boolean {
  return getListAttribute(getAttribute)(attributeInstanceId).includes(
    option.value
  )
}

function isSingleSelected(
  getAttribute: GetAttribute,
  attributeInstanceId: string,
  option: PredefinedOption
): boolean {
  return option.value === getAttribute(attributeInstanceId)
}

function selectSingleAnswer(
  setAttribute: SetAttribute,
  attributeInstanceId: string,
  selectedOption: PredefinedOption
) {
  const attr = attributeInstanceId
  setAttribute(attr, selectedOption.value, "submit")
}

function selectMultiAnswer(
  getAttribute: GetAttribute,
  setAttribute: SetAttribute,
  attributeInstanceId: string,
  neutralAnswerValue: string | undefined,
  selectedOption: PredefinedOption
) {
  const attr = attributeInstanceId
  if (selectedOption.value === neutralAnswerValue) {
    setAttribute(attr, [selectedOption.value], "update")
  } else {
    const currentValues = getListAttribute(getAttribute)(attr)
    if (currentValues.includes(selectedOption.value)) {
      setAttribute(
        attr,
        currentValues.filter((opt) => opt !== selectedOption.value),
        "update"
      )
    } else {
      setAttribute(
        attr,
        [...currentValues, selectedOption.value].filter(
          (opt) => opt !== neutralAnswerValue
        ),
        "update"
      )
    }
  }
}

export const answering = {
  getAutoDrilldownAttribute,
  getNumericAttribute,
  getListAttribute,
  getSingleAttribute,
  selectMultiAnswer,
  isMultiSelected,
  selectSingleAnswer,
  isSingleSelected,
}
