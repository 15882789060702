<script lang="ts">
  import { t } from "../../app/lang/i18n"
  import type { ProductField } from "../../app/types"
  import { removeHTMLMarkup } from "./utils/removeHTMLMarkup"

  export let productFields: ProductField[]

  let sanitizedFields: { values: string; label: string; suffix: string }[] = []
  $: sanitizedFields = removeHTMLMarkup(productFields)
</script>

<section
  role="list"
  aria-label={$t("productFieldsLabel")}
  class="product-fields"
>
  {#each sanitizedFields as field}
    <p
      role="listitem"
      aria-label={`${field.label.trim()}: ${field.values} ${field.suffix ? field.suffix : ""}`}
      class="field"
    >
      {#if field.label.trim()}
        <span class="field-label" aria-hidden="true">{field.label}:</span>
      {/if}
      <span class="field-value" aria-hidden="true">
        {field.values}
      </span>
      {#if field.suffix}
        <span class="field-suffix" aria-hidden="true">
          {field.suffix}
        </span>
      {/if}
    </p>
  {/each}
</section>

<style>
  .product-fields {
    padding: 0;
    /* margin: 1rem 0; */
  }

  .field {
    font-size: var(--product-card-small-font-size, 0.875rem);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin-bottom: 0.25rem;
    margin-top: 0;

    & .field-label {
      font-weight: var(--aiden-product-field-label-font-weight, bold);
      color: var(--aiden-product-field-label-color, inherit);
    }

    & .field-value {
      font-weight: var(--aiden-product-field-value-font-weight, normal);
      color: var(--aiden-product-field-value-color, inherit);
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      min-width: 0;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .field-suffix {
      font-weight: var(--aiden-product-field-suffix-font-weight, normal);
      color: var(--aiden-product-field-suffix-color, inherit);
    }
  }
</style>
