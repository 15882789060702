<script lang="ts">
  import type { CustomerInfoFormConfig } from "../../types"
  import CustomerInfoForm from "./CustomerInfoForm.svelte"
  import { createFormState, type FormStateStore } from "./CustomerInfoFormStore"
  import Plane from "../../../ui/Plane.svelte"
  import { appAnalyticsStore } from "../../../analytics/appAnalytics"

  export let formConfig: CustomerInfoFormConfig

  const formState: FormStateStore = createFormState(
    (email, checkboxChecked) => {
      $appAnalyticsStore.customerInformationFormSubmittedEvent(
        { type: "EMPTY_ADVICE" },
        email,
        { checked: checkboxChecked, checkboxText: formConfig.checkboxLabel }
      )
    }
  )
</script>

<Plane>
  <CustomerInfoForm {formConfig} {formState} />
</Plane>
