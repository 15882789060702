<script lang="ts">
  export let checked = false
</script>

<div class:checked {...$$restProps}>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
</div>

<style>
  div {
    position: relative;
    flex-shrink: 0;
    width: clamp(24px, 1.5rem, 100px);
    height: clamp(24px, 1.5rem, 100px);
    display: inline-block;
    box-sizing: border-box;
    display: block;
    border: 2px solid var(--aiden-checkbox-border-color, transparent);
    background-color: var(--aiden-checkbox-background-color, #ebebeb);
    border-radius: clamp(0rem, var(--aiden-checkbox-border-radius, 0.25rem), 1rem);
    z-index: 5;
    transition: border-color background-color color 250ms ease-out;
    -webkit-transition: border-color background-color color 250ms ease-out;
    box-sizing: border-box;
  }

  div svg {
    box-sizing: border-box;
    display: block;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    margin-top: -2px;
    margin-left: -2px;
    color: transparent;
    top: 1px;
    left: 1px;
    border: 2px solid transparent;
    z-index: 5;
    transition: color 250ms ease-out;
    -webkit-transition: color 250ms ease-out;
  }

  .checked {
    border-color: var(--aiden-checkbox-border-color-checked, #000);
    background-color: var(--aiden-checkbox-background-color-checked, #000);
    color: var(--aiden-checkbox-check-color-checked, #fff);
  }
  .checked svg {
    color: var(--aiden-checkbox-check-color-checked, #fff);
    /* color: white; */
    /* mix-blend-mode: luminosity; */
    /* filter: invert(1) grayscale(1) brightness(1.3) contrast(9000); */
  }
</style>
