<script lang="ts">
  import { type Page as PageT } from "../../app/components/page/toPage"
  import { navigationContext } from "../../app/svelte-stores/navigationStore"
  import Page from "../../app/components/page/Page.svelte"

  export let page: PageT = {
    type: "question",
    screenId: "question",
    title: "Drilldown answer question title",
    text: "",
    media: undefined,
    questionConfig: {
      type: "manual-drilldown",
      complete: true,
      attributeInstanceId: "string",
      levelLabels: [],
      options: [],
    },
  }
  $: {
    navigationContext.set({
      refreshing: false,
      loadingNextScreen: false,
      startingOver: false,
      loadingPreviousScreen: false,
      hasNextScreen: true,
      hasPreviousScreen: true,
      initializing: false,
      progress: 30,
      prev: () => {},
      next: () => {},
      reset: () => {},
    })
  }
</script>

<Page
  {page}
  isPreview
  hidePrev={false}
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
></Page>
