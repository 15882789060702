import { useEffect, useState } from "react"
import {
  ImageObject,
  mediaForAdvisor,
  mediaForQuestion,
} from "~/api/media"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { useAuth } from "~/context/auth"

export default function useMedia(
  mediaFor: { type: "question" | "screen"; id: string },
  mediaType: string
): ImageObject[] {
  const { id: advisorId } = useAdvisorContext()
  const { token, organisationId } = useAuth()
  const [media, setMedia] = useState<ImageObject[]>([])

  useEffect(() => {
    function loadMedia(token) {
      if (mediaFor.type === "question") {
        mediaForQuestion(advisorId, mediaFor.id, {
          token,
          organisationId,
        }).then(setMedia)
      } else if (mediaFor.type === "screen") {
        mediaForAdvisor(advisorId, [mediaFor.id], {
          token,
          organisationId,
        }).then(setMedia)
      }
    }
    if (token) {
      loadMedia(token)
      const handler = (e) => {
        if (e.data.type === "MEDIA_CHANGED") {
          loadMedia(token)
        }
      }
      window.addEventListener("message", handler)
      return () => {
        window.removeEventListener("message", handler)
      }
    }
  }, [mediaType, advisorId, mediaFor.id, token])

  return media
}
