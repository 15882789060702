<script lang="ts">
  import Page from "../../app/components/page/Page.svelte"
  import type { Page as PageT } from "../../app/components/page/toPage"
  import previewImg from "./preview-img.jpg"

  export let page: PageT = {
    type: "info-page",
    screenId: "info",
    title: "Multi answer question title",
    text: "Maecenas sed diam eget risus varius blandit sit amet non magna. Maecenas sed diam eget risus varius blandit sit amet non magna. Donec sed odio dui. Maecenas sed diam eget risus varius blandit sit amet non magna. \n\nCurabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio dui.",
    additionalText: "Additional text. Lorem ipsum dolor.",
    media: {
      type: "image",
      src: previewImg,
    },
    customerInfoForm: {
      enabled: false,
      showCheckbox: false,
      checkboxLabel: "",
      formSubmittedMessage: "",
      formIdentifier: "id",
    },
  }
</script>

<Page
  {page}
  isPreview
  hidePrev={false}
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
/>
