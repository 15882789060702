<script lang="ts">
  import Page from "../../app/components/page/Page.svelte"
  import type { Page as PageT } from "../../app/components/page/toPage"
  import { navigationContext } from "../../app/svelte-stores/navigationStore"

  export let page: PageT = {
    type: "question",
    screenId: "question",
    title: "Numeric answer question title",
    text: "Numeric answer question intro text. Ipsum Mattis Nibh Euismod Tortor",
    media: undefined,
    questionConfig: {
      type: "numeric",
      complete: true,
      attributeInstanceId: "string",
      skipAnswerOption: "true",
      config: {
        allowsDecimals: true,
        inputInfoLabel: "cm",
        inputLabel: "Enter a number",
        inputInfoPosition: "prefix",
      },
    },
  }
  $: {
    navigationContext.set({
      refreshing: false,
      loadingNextScreen: false,
      startingOver: false,
      loadingPreviousScreen: false,
      hasNextScreen: true,
      hasPreviousScreen: true,
      initializing: false,
      progress: 30,
      prev: () => {},
      next: () => {},
      reset: () => {},
    })
  }
</script>

<Page
  {page}
  isPreview
  hidePrev={false}
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
></Page>
