import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import { UUID } from "~/store/types"
import { Question, QuestionMediaType } from "~/store/questions"
import { ScreenMediaType } from "~/store/screens"
import { getAnswers } from "~/store/answers/selectors"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"

type MediaEditorProps = {
  advisorId: UUID
  mediaId: UUID

  answers: {
    id: string
    title: string
  }[]
  authToken: string
  apiUrl: string
  mediaType: QuestionMediaType | ScreenMediaType
}

const MediaEditor: React.FC<MediaEditorProps> = ({
  advisorId,
  mediaId,
  answers,
  authToken,
  apiUrl,
  mediaType,
}) => {
  const [ports, setPorts] = React.useState<any>(undefined)

  React.useEffect(() => {
    if (ports) {
      const mediaChangedHandler = () => {
        window.postMessage({ type: "MEDIA_CHANGED" })
      }
      ports.mediaTypeSelected.send(JSON.stringify(mediaType))

      ports.mediaChanged.subscribe(mediaChangedHandler)
      return () => {
        ports.mediaChanged.unsubscribe(mediaChangedHandler)
      }
    }
  }, [ports, mediaType])

  return (
    // @ts-ignore
    <Elm
      src={ElmModule.MediaEditor}
      flags={{
        advisorId: advisorId,
        mediaId: mediaId,
        answers: answers,
        authToken: authToken,
        apiUrl: apiUrl,
        mediaType: mediaType,
      }}
      ports={setPorts}
    />
  )
}

export default MediaEditor
