import React from "react"
import { useTranslation } from "react-i18next"
import ArrowBendUpRight from "~/components/icons/ArrowBendUpRight"

export const EmptyPreview: React.FC = () => {
  const { t } = useTranslation()
  
  return (
    <div className="conversation--sidebar-preview-empty">
      <ArrowBendUpRight />
      <p>{t("previewSidebar.empty")}</p>
    </div>
  )
}
