<script lang="ts">
  import ReviewStar from "./ReviewStar.svelte"
  import { selectedLocale, t } from "../../app/lang/i18n"

  export let score: number
  export let count: number | undefined = undefined

  function clamp(value: number, min: number, max: number): number {
    return value < 0.0 ? 0.0 : value > 5.0 ? 5.0 : value
  }

  $: reviewScore = clamp(score, 0.0, 5.0)

  // let formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
  $: formatter = new Intl.NumberFormat($selectedLocale)

  let specs: ("empty" | "quarter" | "half" | "three-quarter" | "full")[] = [
    ...Array(5).keys(),
  ].map(() => "empty")

  $: {
    reviewScore = isFinite(score)
      ? Math.round(clamp(score, 0.0, 5.0) * 4) / 4
      : 0.0
    let [whole, remaining] = reviewScore.toFixed(2).split(".")
    let wholeScore = parseInt(whole)
    let remainder = parseInt(remaining)

    specs = [...Array(5).keys()].map((i) => {
      if (i < wholeScore) return "full"
      if (i == wholeScore) {
        if (remainder < 25) return "empty"
        if (remainder < 50) return "quarter"
        if (remainder < 75) return "half"
        if (remainder < 100) return "three-quarter"
        return "full"
      }
      return "empty"
    })
  }
</script>

<div class="review-score" data-testid="review-score">
  <div
    class="stars-container"
    role="img"
    aria-label={$t("reviewScoreLabel", { score: score.toString(), count: count?.toString() || "-" })}
    data-testId="review-stars"
  >
    <div class="stars" aria-hidden="true">
      {#each { length: 5 } as _, i}
        <div class="star">
          <ReviewStar step={"full"} />
        </div>
      {/each}
    </div>
    <div aria-hidden="true" class="stars stars-filled">
      {#each specs as s}
        <div
          class="star"
          data-testId={s === "empty" ? "" : "review-star-filled"}
        >
          <ReviewStar step={s} />
        </div>
      {/each}
    </div>
  </div>
  {#if count !== undefined}
    <span aria-hidden="true" class="review-count" data-testid="review-count">
      {#if count > 10000}
        {formatter.format(10000)}+
      {:else}
        {formatter.format(count)}
      {/if}
    </span>
  {/if}
</div>

<style>
  .review-score {
    --star-width: 20px;
    --stars-width: calc(var(--star-width) * 5);
    display: grid;
    grid-template-columns: var(--stars-width, min-content) 1fr;
    gap: 0.25rem;
    align-items: center;
    line-height: 1;
    margin-right: 0.25rem;
  }

  .stars-container {
    position: relative;
    width: var(--stars-width);
    height: 20px;
  }
  .stars {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--aiden-review-star-color, #d6d6d6);
  }
  .stars.stars-filled {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--aiden-review-star-fill-color, #000);
    opacity: 1;
  }

  div.stars {
    display: grid;
    grid-template-columns: repeat(5, var(--star-width, 20px));
    width: var(--stars-width);
  }

  span.review-count {
    color: var(--aiden-product-card-text-color, #333);
    font-size: var(--product-card-small-font-size, 0.875rem);
    margin-top: 0.125rem;
    text-wrap: nowrap;
  }
</style>
