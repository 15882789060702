import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Montrer plus",
  showLess: "Montrer moins",
  bestMatch: "Meilleur match",
  view: "Voir",
  customAction: "",
  notFound: "404: introuvable",
  reset: "Recommencer",
  close: "Fermer",
  noAdviceTitle:
    "Actuellement, il n’y a pas de produits disponibles qui sont entièrement adaptés à vos préférences.",
  noAdviceMessage: "Veuillez modifier vos préférences ou réessayer plus tard.",
  prev: "Précédent",
  next: "Suivant",
  backToAdvice: "Retour aux conseils",
  dontKnow: "Sauter",
  email: "Adresse e-mail",
  send: "Envoyer",
  emailError: "Entrez une adresse mail valide",
  readMore: "Lire la suite",
  selectAnAnswer: "Sélectionnez une réponse",
  answerNotListed: "Ma réponse n'est pas listée",
  whyThisProductForMe: "Pourquoi ce conseil?",
  alternative: "alternative",
  alternatives: "alternatives",
  seeAlso: "Regarde aussi",
  productFeaturesLabel: "Dans quelle mesure cela correspond-il à vos préférences ?",
  badMatchLabel: "Mauvaise correspondance",
  goodMatchLabel: "Bonne correspondance",
  perfectMatchLabel: "Correspondance parfaite",
  singleAnswerQuestionLegend: "Choisissez une option et continuez.",
  multiAnswerQuestionLegend: "Choisissez une ou plusieurs options.",
  multiAnswerQuestionWithNeutralLegend: "Choisissez une ou plusieurs options, ou l'option du bas si vous n'avez pas de préférence.",
  manualDrilldownLegend: "Sélectionnez une option. S'il y a une question complémentaire, elle apparaîtra après avoir choisi une réponse. Utilisez le bouton « Suivant » pour continuer après avoir répondu à toutes les questions.",
  autoDrilldownLegend: "Sélectionnez une option. S'il y a une question complémentaire, elle apparaîtra après avoir choisi une réponse. Utilisez le bouton « Suivant » pour continuer après avoir répondu à toutes les questions. Votre réponse n'est pas répertoriée? Sélectionnez ensuite « Ma réponse n'est pas listée ».",
  loading: "Chargement",
  progress: "Progrès",
  numberInputDefaultLabel: "Entrez un numéro.",
  numberInputWithUnitLabel: "Entrez un nombre dans {{unit}}.",
  actionSucceededLabel: "Réussi",
  actionFailedLabel: "Échoué",
  reviewScoreLabel: "Ce produit est noté : {{score}} étoiles sur 5 sur la base de {{count}} avis.",
  energyLabel: "Ce produit porte l'étiquette énergétique : {{score}} sur l'échelle de : {{scale}}.",
  previousPriceLabel: "Ce produit était auparavant au prix de {{price}}.",
  actualPriceLabel: "La description actuelle du prix de ce produit est : {{prefix}} {{price}} {{suffix}}.",
  productFieldsLabel: "Autres informations",
  closeLabel: "Fermer le moteur de recherche de produits",
  resetLabel: "Redémarrer la recherche de produits",
  prevLabel: "Aller à l'étape précédente",
  nextLabel: "Passer à l'étape suivante",
}

export default translations
