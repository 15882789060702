import React from "react"
import { useAdvisorContext } from "~/containers/advisors/AdvisorContainer"
import { getAdvisor } from "~/store/advisors/selectors"
import { ApplicationState } from "~/store"
import { useSelector } from "react-redux"
import { Page } from "../../../../../../webshop-frontend/src/app/components/page/toPage"

export const PreviewSidebar: React.FC<{ preview: Page }> = (props) => {
  const { preview } = props
  const info = useAdvisorContext()
  const advisor = useSelector((state: ApplicationState) =>
    getAdvisor(state, info.id)
  )

  return (
    <>
      <div
        style={{
          padding: ".5rem 1.5rem",
          height: "4.75rem",
          display: "grid",
          gridTemplateColumns: "max-content min-content",
          gap: "1rem",
          alignItems: "center",
          position: "sticky",
          top: "0",
          background: "#fff",
          borderBottom: "2px solid #edf3f9",
        }}
      >
        <h3 className="small-text mb-0 subtle-text">Visual preview</h3>
        <div>{props.children}</div>
      </div>
      <div className="scoped-variables"
        style={{
          overflow: "auto",
        }}
      >
        {/* @ts-ignore */}
        <aiden-screen-preview-v2
          preview={JSON.stringify(preview)}
          locale={advisor?.locales[0] || "nl"}
          style={{
            height: "100%",
            display: "block",
          }}
        />
      </div>
    </>
  )
}
