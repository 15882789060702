<script lang="ts">
  import EmbedInterop from "./interop/EmbedInterop"
  import modalCloseOnEscape from "./interop/closeOnEscape"
  import modalFocusTrap from "./interop/focusTrap"

  let container: HTMLElement

  const modalCloseId = "aiden-modal-close-button"

  export let advisorId: string
  export let screenId: string
  let lastScreenId: string = ""

  let scrollContainer: any | undefined = undefined

  $: {
    if (lastScreenId !== screenId) {
      scrollContainer?.scrollTo({ top: 0, behavior: "smooth" })
    }
    lastScreenId = screenId
  }

  const sendBackgroundClick = (event: KeyboardEvent | MouseEvent) => {
    const target = event.target as Element
    if (container === target || target.id === modalCloseId) {
      event.stopPropagation()
      EmbedInterop.sendBackgroundClickEvent(advisorId)
    }
  }

  $: modalCloseOnEscape(modalCloseId)
  $: modalFocusTrap(container)
</script>

<div tabindex="-1" bind:this={container} on:click={sendBackgroundClick}>
  <div class="sidebar-container" bind:this={scrollContainer}>
    <slot />
  </div>
</div>

<style>
  div {
    height: 100vh;
    height: 100dvh;
    box-sizing: border-box;
    animation: appear 350ms ease-out;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.35);
  }

  @keyframes animate {
    from {
      /* max-width: 0; */
      opacity: 0;
      /* transform: translateX(10rem); */
    }
    to {
      opacity: 1;
      /* transform: translateX(0); */
    }
  }
  .sidebar-container {
    /* animation: animate 350ms ease-out; */
  }
  .sidebar-container {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    margin-left: auto;
    margin-right: 0;
    max-width: 38rem;
    height: 100vh;
    height: 100dvh;
    overflow-y: auto;
    /* scrollbar-gutter: stable; */
    box-sizing: border-box;

    display: grid;
    grid-template-rows: 1fr min-content;
  }
</style>
