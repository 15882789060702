import {
  AdviceScreen,
  InfoScreen,
  IntroScreen,
  ScreenMediaType,
} from "~/store/screens"
import previewImg from "../../../../../../webshop-frontend/src/cms-preview/preview-screens/preview-product-img.jpg"
import {
  Media,
  QuestionConfig,
  type Recommendation as RecommendationT,
  type Page,
} from "../../../../../../webshop-frontend/src/app/components/page/toPage"
import { ImageObject } from "~/api/media"
import { Advisor } from "~/store/advisors"
import {
  DrilldownAnswerOption,
  Question,
  QuestionMediaType,
  QuestionType,
} from "~/store/questions"
import { Answer } from "~/store/answers/types"
import { DrilldownOption } from "../../../../../../webshop-frontend/src/app/types"

export function introScreenToPage(screen: IntroScreen): Page {
  return {
    type: "start-page",
    screenId: screen.id,
    title: screen.title,
    text: screen.text,
    legacyShowOnlyButton: false,
    additionalText: screen.info || "",
    buttonText: screen.buttonText,
  }
}

export function infoScreenToPage(
  screen: InfoScreen,
  media: ImageObject[]
): Page {
  let pageMedia: Media | undefined = undefined

  if (screen?.mediaType === ScreenMediaType.SCREEN_WITH_VIDEO) {
    const video = media.find((m) => m.referenceId == screen?.id)?.metaInfo
      ?.embed
    if (video) {
      pageMedia = { type: "video", src: video }
    }
  }
  if (screen?.mediaType === ScreenMediaType.SCREEN_WITH_IMAGE) {
    const image = getImage(screen.id, media)

    if (image) {
      pageMedia = { type: "image", src: image }
    }
  }

  return {
    type: "info-page",
    screenId: screen.id,
    title: screen.title,
    text: screen.text,
    additionalText: screen.info,
    media: pageMedia,
    customerInfoForm: {
      formIdentifier: screen.customerInfo?.formIdentifier || "",
      enabled: screen.customerInfo?.showForm || false,
      showCheckbox: screen.customerInfo?.showCheckbox || false,
      checkboxLabel: screen.customerInfo?.checkboxLabel || "",
      formSubmittedMessage: screen.customerInfo?.formSubmittedMessage || "",
    },
  }
}

export function adviceScreenToPage(
  screen: AdviceScreen,
  customerInfoConfig: Advisor["customerInfo"]
): Page {
  return {
    type: "advice-page",
    screenId: screen.id,
    title: screen.title,
    text: screen.text,
    additionalText: screen.info,
    recommendations: [
      createRecommendation(screen, true),
      createRecommendation(screen),
      createRecommendation(screen),
    ],
    customerInfoForm: {
      enabled: customerInfoConfig.showForm,
      showCheckbox: customerInfoConfig.showCheckbox,
      checkboxLabel: customerInfoConfig.checkboxLabel,
      formSubmittedMessage: customerInfoConfig.formSubmittedMessage,
      ctaButtonLabel: customerInfoConfig.ctaButtonLabel,
      title: customerInfoConfig.title,
      intro: customerInfoConfig.intro,
    },
    actions: [],
  }
}

export function emptyAdviceScreenToPage(screen: AdviceScreen): Page {
  return {
    type: "empty-advice-page",
    screenId: screen.id,
    title: screen.noResultsTitle,
    text: screen.noResultsText,
    customerInfoForm: {
      enabled: screen.noResultsCustomerInfo?.showForm || false,
      showCheckbox: screen.noResultsCustomerInfo?.showCheckbox || false,
      checkboxLabel: screen.noResultsCustomerInfo?.checkboxLabel || "",
      formSubmittedMessage:
        screen.noResultsCustomerInfo?.formSubmittedMessage || "",
    },
  }
}

export function questionToPage(
  question: Question,
  answers: Answer[],
  media: ImageObject[]
): Page {
  let pageMedia: Media | undefined = undefined

  if (question.mediaType === QuestionMediaType.QUESTION_WITH_VIDEO) {
    const video = media.find((m) => m.referenceId == question.id)?.metaInfo
      ?.embed
    if (video) {
      pageMedia = { type: "video", src: video }
    }
  }
  if (question.mediaType === QuestionMediaType.QUESTION_WITH_IMAGE) {
    const image = getImage(question.id, media)
    if (image) {
      pageMedia = { type: "image", src: image }
    }
  }

  const questionConfig: QuestionConfig | undefined =
    question.type === QuestionType.Single
      ? {
          type: "single",
          complete: true,
          attributeInstanceId: "",
          neutralAnswerValue: undefined,
          options: answers.map((a) => {
            return {
              label: a.title,
              helpText: a.helpText,
              image:
                question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE
                  ? getImage(a.id, media)
                  : undefined,
              value: a.id,
            }
          }),
        }
      : question.type === QuestionType.Multi
      ? {
          type: "multi",
          complete: true,
          attributeInstanceId: "",
          neutralAnswerValue: undefined,
          options: answers.map((a) => {
            return {
              label: a.title,
              helpText: a.helpText,
              image:
                question.mediaType === QuestionMediaType.ANSWERS_WITH_IMAGE
                  ? getImage(a.id, media)
                  : undefined,
              value: a.id,
            }
          }),
        }
      : question.type === QuestionType.Numeric
      ? {
          type: "numeric",
          complete: true,
          attributeInstanceId: "",
          skipAnswerOption: question.config.optional ? "skip" : undefined,
          config: {
            allowsDecimals: question.config.allowDecimals,
            inputLabel: question.config.placeholder,
            inputInfoLabel: question.config.inputInfo.label,
            inputInfoPosition:
              question.config.inputInfo.position === "PREFIX"
                ? "prefix"
                : "suffix",
          },
        }
      : question.type === QuestionType.Drilldown
      ? {
          type: "manual-drilldown",
          complete: true,
          attributeInstanceId: "",
          levelLabels: question.config.levelConfiguration.map((l) => l.label),
          options: question.config.answers.map(toManualDrilldownOption),
        }
      : question.type === QuestionType.AutoDrilldown
      ? {
          type: "auto-drilldown",
          complete: true,
          attributeInstanceId: "",
          levelsConfiguration: question.config.levelConfiguration.map((opt) => {
            return { label: opt.label, sourceField: "" }
          }),
          neutralAnswerValue: "neutral-answer",
        }
      : undefined

  return {
    type: "question",
    screenId: question.id,
    title: question.title,
    text: question.helpText,
    media: pageMedia,
    questionConfig: questionConfig as any,
  }
}

function getImage(id: string, media: ImageObject[]): string {
  const obj = media.find((m) => m.referenceId == id)?.urls
  if (obj instanceof Object) {
    return obj["560x300"] || ""
  }
  return ""
}
function toManualDrilldownOption(opt: DrilldownAnswerOption): DrilldownOption {
  if ("branchId" in opt) {
    return {
      title: opt.title,
      answers: opt.answers.map(toManualDrilldownOption),
    }
  } else {
    return { id: opt.id, title: opt.title }
  }
}

const createRecommendation = (
  adviceScreen: AdviceScreen,
  isBestMatch: boolean = false
): RecommendationT => {
  return {
    productName: "Product name",
    imageUrl: previewImg,
    productUrl: "https://",
    price: adviceScreen.showCurrency ? "€ 12" : "12",
    salePrice: {
      previousPriceAnnotation: "",
      salePeriodEnd: undefined,
      salePeriodStart: undefined,
      price: adviceScreen.showCurrency ? "€ 5" : "5",
    },

    state:
      adviceScreen.showBestMatch && isBestMatch
        ? {
            bestMatchText: adviceScreen.bestMatchText,
            type: "best-match",
          }
        : undefined,

    recommendationContext: {
      recommendationPosition: 0,
      productContext: "RECOMMENDATION",
    },
    productId: "product-id",
    clientProductId: "",
    isProductCheckProduct: false,
    productData: {},
    specification: {
      price: null,
    },
    traceability: [
      {
        score: "bad",
        answer: "Bad match",
      },
      {
        score: "good",
        answer: "Good match",
      },
      {
        score: "perfect",
        answer: "Perfect match",
      },
    ],
    buttonCtaText: adviceScreen.buttonText,

    energyLabelField: adviceScreen.showEnergyLabel
      ? {
          type: "energy-label",
          energyLabel: {
            scale: "EU-G-A",
            classification: "A",
            labelLink: "",
          },
          productSheet: {
            label: "Product sheet",
            url: "",
          },
        }
      : undefined,

    additionalFields: [
      ...(adviceScreen.extraField1
        ? [
            {
              label: adviceScreen.extraField1.label || adviceScreen.extraField1.column,
              suffix: adviceScreen.extraField1.suffix || "",
              values: ["..."],
            },
          ]
        : []),
      ...(adviceScreen.extraField2
        ? [
            {
              label: adviceScreen.extraField2.label || adviceScreen.extraField2.column,
              suffix: adviceScreen.extraField2.suffix || "",
              values: ["..."],
            },
          ]
        : []),
      ...(adviceScreen.extraField3
        ? [
            {
              label: adviceScreen.extraField3.label || adviceScreen.extraField3.column,
              suffix: adviceScreen.extraField3.suffix || "",
              values: ["..."],
            },
          ]
        : []),
    ],

    priceSuffix: adviceScreen.showPriceSuffix ? adviceScreen.priceSuffix : "",
    pricePrefix: adviceScreen.showPricePrefix ? adviceScreen.pricePrefix : "",
    variants: [],

    reviewScore: adviceScreen.showReviewScore ? 3.5 : undefined,
    reviewCount: adviceScreen.showReviewScore ? 12345 : undefined,

    display: {
      bestMatch: adviceScreen.showBestMatch,
      traceability: adviceScreen.showMatchingIndicators,
      price: adviceScreen.showPrice,
      productCta: adviceScreen.showProductLink,
      ctaType: adviceScreen.callToActionType,
    },
  }
}
