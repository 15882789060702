<svelte:options customElement="aiden-screen-preview" />

<script lang="ts">
  import AdvicePage from "./preview-screens/AdvicePage.svelte"
  import BottomNavigation from "../ui/BottomNavigation.svelte"
  import TopNavigation from "../ui/TopNavigation.svelte"
  import DrilldownQuestionPage from "./preview-screens/DrilldownQuestionPage.svelte"
  import InfoPage from "./preview-screens/InfoPage.svelte"
  import MultiAnswerQuestionPage from "./preview-screens/MultiAnswerQuestionPage.svelte"
  import NumericQuestionPage from "./preview-screens/NumericQuestionPage.svelte"
  import SingleAnswerQuestionPage from "./preview-screens/SingleAnswerQuestionPage.svelte"
  import StartPage from "./preview-screens/StartPage.svelte"

  export let type: string = "start-page"
  export let cardCount: string | undefined = undefined

  let cardsToDisplay = 3
  $: {
    if (cardCount !== undefined || cardCount !== null) {
      try {
        cardsToDisplay = parseInt(cardCount || "")
      } catch (e) {}
    } else {
      cardsToDisplay = 3
    }
  }
</script>

<div>
  {#if type === "start-page"}
    <TopNavigation hideStartOver hideProgress />
    <StartPage />
  {:else if type === "single-answer-question"}
    <TopNavigation />
    <SingleAnswerQuestionPage>
      <BottomNavigation hideNext />
    </SingleAnswerQuestionPage>
  {:else if type === "multi-answer-question"}
    <TopNavigation />
    <MultiAnswerQuestionPage />
  {:else if type === "numeric-question"}
    <TopNavigation />
    <NumericQuestionPage>
      <BottomNavigation />
    </NumericQuestionPage>
  {:else if type === "drilldown-question"}
    <TopNavigation />
    <DrilldownQuestionPage>
      <BottomNavigation />
    </DrilldownQuestionPage>
  {:else if type === "info-page"}
    <TopNavigation />
    <InfoPage>
      <BottomNavigation />
    </InfoPage>
  {:else if type === "advice-page"}
    <AdvicePage cardCount={cardsToDisplay}>
      <BottomNavigation hideNext />
    </AdvicePage>
  {:else}
    <p>unknown screen type: {type}</p>
  {/if}
</div>

<style>
  div {
    /* padding: 1rem 0; */
    --plane-color:var(--aiden-app-background-color, #fff) ;
    background: var(--plane-color);
  }
  div:has(.advice-page-top-header) {
    background: var(
      --aiden-advice-page-header-background-color,
      var(--aiden-app-background-color, #fff)
    );
  }
  div:has(.start-page) {
    --plane-color: var(--aiden-start-page-background-color, #fff);
    --aiden-app-background-color: var(
      --aiden-start-page-background-color,
      #fff
    );
    background-color: var(--plane-color);

    --aiden-navigation-button-color: var(
      --aiden-start-page-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-start-page-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-start-page-navigation-button-color-hover
    );
  }
  div :global(*) {
    cursor: default !important;
  }
</style>
