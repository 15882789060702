<script lang="ts">
  import type { IntegrationsAnalyticsForComponents } from "../../analytics/integrations/IntegrationsAnalytics"
  import { t } from "../../app/lang/i18n"
  import { type VariantProduct } from "../../app/types"
  import ImageWithFallback from "./ImageWithFallback.svelte"
  import type {
    ProductRecommendationContext,
    SendInternalAnalytic,
  } from "../../analytics/internal/types"


  import { abTestStore } from "../../app/ABTest"
  import ContextMenuHandler from "../../helpers/contextMenuHandler"
  import { type ProductClick, productClickHandler } from "../../app/components/recommendation/productClickHandler"

  export let variants: VariantProduct[] = []
  export let onClick: ProductClick | undefined = undefined

  let collapsed = true

  function open() {
    collapsed = !collapsed
  }
  let width = 300
  let maxVariants = 1
  let variantsToShow: VariantProduct[] = variants.slice(0, maxVariants)
  let remainder = variants.length - variantsToShow.length
  $: {
    const variantWidth = 60 //width + gap
    // Ensure at least one is shown
    const calcWidth = width || variantWidth
    maxVariants = Math.abs(Math.floor(calcWidth / variantWidth))
    variantsToShow = collapsed ? variants.slice(0, maxVariants) : variants
    remainder = collapsed ? variants.length - variantsToShow.length : 0
    if (remainder === 1) {
      remainder = 0
      collapsed = false
      variantsToShow = variants
    }
  }
</script>

{#if variants.length}
  <div
    class="product-variants"
    bind:clientWidth={width}
    class:is-open={!collapsed}
  >
    <div><strong style="font-size: 0.875rem;"> {$t("seeAlso")}:</strong></div>
    <div class="variant-options">
      {#each variantsToShow as variant}
        <a
          class="variant-option"
          data-testid="product-variant"
          on:click={(e) =>
            onClick && onClick(e, variant, "PRODUCT_IMAGE", "LINK")}
          use:ContextMenuHandler={() =>
            onClick &&
            onClick(undefined, variant, "PRODUCT_IMAGE", "LINK_RIGHT_CLICK")}
          href={variant.productUrl}
          title={variant.productName}
          target="_blank"
        >
          <ImageWithFallback src={variant.imageUrl} alt={variant.productName} />
        </a>
      {/each}
      {#if remainder}
        <button on:click={open} class="remainder"> +{remainder} </button>
      {/if}
    </div>
  </div>
{/if}

<style>
  .variant-option {
    align-items: center;
    border-radius: 0.25rem;
    text-align: center;
    width: 3rem;
    min-height: 3rem;
    max-height: 4rem;
    border: 1px solid #f4f4f4;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    --animation-translate-y-offset: 2rem;
    animation: pop-in 250ms var(--aiden-animation-overshoot-function);
    outline: 1px solid transparent;
    transition: outline 150ms ease-out;
  }
  .variant-option:hover,
  .variant-option:focus-visible {
    outline: 1px solid var(--aiden-highlight-color);
  }

  .variant-options {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .is-open .variant-options {
    flex-wrap: wrap;
  }
  .product-variants :global(.product-card-image) {
    max-width: 100%;
    max-height: 4rem;
    object-fit: contain;
  }
  .remainder {
    background-color: #f4f4f4;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  .remainder:hover {
    text-decoration: underline;
  }
</style>
