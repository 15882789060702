import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Daha fazlasını göster",
  showLess: "Daha az göster",
  bestMatch: "En iyi eşleşme",
  view: "Bakmak",
  customAction: "Alışveriş sepetinde",
  notFound: "404: Bulunamadı",
  reset: "Yeniden başla",
  close: "Kapalı",
  noAdviceTitle:
    "Şu anda tercihlerinize tam olarak uyan bir ürün mevcut değil.",
  noAdviceMessage: "Tercihlerinizi değiştirebilir veya daha sonra tekrar deneyebilirsiniz.",
  prev: "Öncesi",
  next: "Sonraki",
  backToAdvice: "Tavsiyeye geri dön",
  dontKnow: "Atlamak",
  email: "E-posta adresi",
  send: "Göndermek",
  emailError: "Geçerli bir e",
  readMore: "Devamını oku",
  selectAnAnswer: "Bir yanıt seçin",
  answerNotListed: "Cevabım listelenmiyor",
  whyThisProductForMe: "Bu tavsiye neden?",
  alternative: "alternatif",
  alternatives: "alternatifler",
  seeAlso: "Ayrıca bakınız",
  productFeaturesLabel: "Bu, tercihlerinize ne kadar uyuyor?",
  badMatchLabel: "Kötü eşleşme",
  goodMatchLabel: "İyi eşleşme",
  perfectMatchLabel: "Mükemmel eşleşme",
  singleAnswerQuestionLegend: "Bir seçeneği seçin ve devam edin.",
  multiAnswerQuestionLegend: "Bir veya daha fazla seçeneği seçin.",
  multiAnswerQuestionWithNeutralLegend: "Bir veya daha fazla seçeneği veya tercihiniz yoksa alt seçeneği seçin.",
  manualDrilldownLegend: "Bir seçeneği seçin. Bir takip sorusu varsa, bir cevap seçildikten sonra görünecektir. Tüm soruları cevapladıktan sonra devam etmek için 'Sonraki' butonunu kullanın.",
  autoDrilldownLegend: "Bir seçeneği seçin. Bir takip sorusu varsa, bir cevap seçildikten sonra görünecektir. Tüm soruları cevapladıktan sonra devam etmek için 'Sonraki' butonunu kullanın. Cevabınız listelenmemiş mi? Daha sonra 'Cevabım listelenmiyor'u seçin.",
  loading: "Yükleniyor",
  progress: "İlerlemek",
  numberInputDefaultLabel: "Bir sayı girin.",
  numberInputWithUnitLabel: "{{unit}} cinsinden bir sayı girin.",
  actionSucceededLabel: "Başarılı",
  actionFailedLabel: "Arızalı",
  reviewScoreLabel: "Bu ürün, {{count}} yoruma göre 5 üzerinden {{score}} yıldızla derecelendirildi.",
  energyLabel: "Bu ürün, {{scale}} ölçeğinde {{score}} enerji etiketine sahiptir.",
  previousPriceLabel: "Bu ürün daha önce {{price}} olarak fiyatlandırılıyordu.",
  actualPriceLabel: "Bu ürünün güncel fiyat açıklaması şu şekildedir: {{prefix}} {{price}} {{suffix}}.",
  productFieldsLabel: "Diğer bilgiler",
  closeLabel: "Ürün bulucuyu kapatın",
  resetLabel: "Ürün bulucuyu yeniden başlatın",
  prevLabel: "Önceki adıma git",
  nextLabel: "Bir sonraki adıma geçin",
}

export default translations
