export default {
  translation: {
    general: {
      tryAgain: "Try again",
      copyOf: "Copy of {{name}}",
      warning: "warning",
      warning_plural: "warnings",
      issues: "issue",
      issues_plural: "issues",
      showMore: "Show more",
      showLess: "Show less",
      feedback: {
        changesSaved: "Changes saved!",
        preferencesSaved: "Preferences saved!",
      },
    },
    languages: {
      "en-US": "English",
      nl: "Dutch",
    },
    imageSelector: {
      placeholder: "Drag 'n' drop an image here, or click to select one",
    },
    tooltips: {
      previewButton: "Preview your app and see the changes you've made.",
      questionTypes: {
        single: "Ask a question that can have only one answer.",
        multi: "Ask a question that can have only multiple answers.",
        numeric: "Ask a user for a number or an amount.",
        drilldown:
          "Help users select the right answer from a (long) list of interdependent options. For example, ask for their current car brand and subsequent model.",
      },
      numberOfProductsInAdvice: {
        header: "Number of advised products",
        body: "You can determine how many products you want to show your customers in the advice. The number of products will be shown as in the example above.",
      },
      CTAButton: {
        header: "CTA button",
        body: 'The call to action button is shown on the product cards in the advice. The button tells your customers what they should be doing once they click on it. For example: "buy now" or "read more".',
      },
      catalogueFilter: {
        header: "Catalogue filter",
        body: "With a catalog filter you can filter a group of products that contain a certain value from your catalogue. For example, all products that are in stock.",
      },
      questionFilter: {
        header: "Question filter",
        body: 'With a question filter you can filter a group of products that have a certain matching with a question and answer. For example: all products that are a good match when "living room" is answered to question "where".',
      },
      matching: {
        bad: "It is a bad fit for the answer.",
        neutral: "It is neither bad nor a good fit for the answer.",
        good: "It is a good fit for the answer, but not perfect.",
        perfect: "It is a perfect fit for the answer, nothing could beat it.",
      },
      filterUnmatched: "Only show the unmatched {{productLabel}}.",
      sidebar: {
        collapsed: "Open the sidebar",
        visible: "Collapse the sidebar",
      },
      advisorOverview: {
        aggregates: {
          sessions: "The total amount of times an app has been started.",
          productClicks:
            "The total amount of sessions that clicked at least 1 product on an advice page.",
          clickRate: "The ratio between advice views and click-through.",
          adviceViews:
            "The total amount of sessions that viewed an advice page.",
        },
      },
      analyze: {
        aggregates: {
          sessions: "The total amount of times the app has been started.",
          productClicks:
            "The total amount of sessions that clicked at least 1 product on the advice page.",
          clickRate: "The ratio between advice views and click-through.",
          adviceViews:
            "The total amount of sessions that viewed the advice page.",
        },
      },
    },
    roles: {
      contributor: "Contributor",
      admin: "Admin",
    },
    themes: {
      light: "Light",
      dark: "Dark",
    },
    forms: {
      selectImage: "Select image",
    },
    invitation: {
      accountInfo: {
        intro: "Set up your Aiden account",
        email: "Email",
      },
      userInfo: {
        intro: "Let your fellow contributors know who you are",
        firstName: "First name",
        firstNamePlaceholder: "How people call you",
        familyName: "Full name (first and last name)",
        familyNamePlaceholder: "Ex. Peter Pan",
        avatar: "Add a photo (optional)",
      },
      error: {
        already_exists: "A user with this email adress already exists.",
        not_found: "This invitation is has already been used or has expired.",
      },
    },
    changePassword: {
      password: "Password",
      passwordConfirmation: "Password Confirmation",
      requirements: {
        length: "Between 8 and 64 characters long",
        capital: "At least one capital letter",
        number: "At least one number",
        special: "At least one of the characters !@#$%^&*=",
      },
      error: {
        invalidConfirmation: "The password and confirmation are not the same",
      },
    },
    advise: "Advice",
    start: "Start",
    back: "Back",
    cancel: "Cancel",
    create: "Create",
    delete: "Delete",
    confirm: "Confirm",
    crop: "Crop",
    ok: "Ok",
    confirmDeletion: "Confirm deletion",
    next: "Next",
    optional: "(optional)",
    questions_plural: "Questions",
    questions: "Question",
    rules_plural: "Rules",
    rules: "Rule",
    reset: "Reset",
    save: "Save",
    update: "Update",
    name: "Name",
    preview: "Preview",
    status: "Status",
    releaseNotes: "Release notes",
    helpSupport: "Help & Support",
    id: "ID",
    emptyAnswer: "Empty answer",
    homeOptions: {
      settings: "Settings",
      logout: "Logout",
    },
    errors: {
      error: "Something went wrong, please try again.",
      save: "We weren't able to save your data. Please try again.",
      saveShort: "Failed to save your changes.",
    },
    combinators: {
      or: "Or",
      and: "And",
    },
    productUpdates: {
      title: "What's new",
      readMore: "Read more",
    },
    settings: {
      categories: {
        me: "Me",
        company: "Company",
      },
      myAccount: {
        navLink: "My account",
        firstName: "First name",
        firstNamePlaceholder: "John",
        familyName: "Full name (first and last name)",
        familyNamePlaceholder: "Ex. Peter Pan",
        email: "Email",
        emailNotice:
          "Your account is linked to your business, so you cannot change your email.",
        personalInfo: "Personal info",
        validation: {
          nameEmpty: "Your first name or full name can't be empty.",
          nameLength: "Your first name or full name is too long.",
        },
      },
      preferences: {
        navLink: "Preferences",
        language: "Language",
        theme: "Theme",
      },
      security: { navLink: "Security" },
      companySettings: {
        navLink: "Settings",
        language: "Standard language",
        name: "Company name",
        validation: {
          nameEmpty: "Your company name can't be empty",
        },
      },
      integrationsSettings: {
        navLink: "Integrations",
        title: "Integrations",
        text: "Use the toggles to turn the integrations on/off.",
      },
      apiKeys: {
        navLink: "API keys",
      },
      users: {
        title: "User management",
        navLink: "Users",
        inviteTitle: "Invite user",
        overviewTitle: "Users",
        overview: {
          headers: {
            user: "User",
            role: "Role",
          },
          filterPlaceholder: "Filter by email or name...",
          invitePending: "Invite pending",
          invitationExpired: "Invitation expired",
          resendInvitation: "Resend invitation",
          invitationSend: "Invitation send!",
          deleteConfirmHeader: "Are you sure?",
          deleteConfirmText:
            "Removing a user will revoke their access and this cannot be undone other than sending a new invitation.",
          deleteButton: "Delete user",
        },
        emailLabel: "Email",
        roleLabel: "Role",
        feedback: {
          success: "An invitation was sent to {{email}}.",
          failed: "Could not invite {{email}}",
          roleUpdated: "{{name}}'s role was changed to '{{role}}'",
          accountDeleted: "{{name}}'s account was deleted",
          accountNotDeleted: "Could not delete {{name}}'s account",
          inviteRevoked: "{{email}}'s invitation was revoked",
          emailInvalid: "This e-mail is invalid",
        },
      },
      billing: { navLink: "Billing" },
    },
    months: {
      januari: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    date: {
      format: "{{month}} {{day}}, {{year}}",
    },
    datetime: {
      now: "just now",
      seconds: "{{count}} seconds ago",
      minutes: "a minute ago",
      minutes_plural: "{{count}} minutes ago",
      hours: "an hour ago",
      hours_plural: "{{count}} hours ago",
      days: "yesterday",
      days_plural: "{{count}} days ago",
      weeks: "a week ago",
      weeks_plural: "{{count}} weeks ago",
      months: "a month ago",
      months_plural: "{{count}} months ago",
      years: "a year ago",
      years_plural: "{{count}} years ago",
    },
    mainNav: {
      advisors: "Apps",
    },
    advisor: {
      notices: {
        advice: {
          HEADER_MIN_LENGTH: "The customer contact header is missing",
          INTRO_MIN_LENGTH: "The customer contact intro text is missing",
          SUBMITTED_MESSAGE_MIN_LENGTH:
            "The customer contact confirmation message is missing",
          CTA_BUTTON_MIN_LENGTH:
            "The customer contact call-to-action button text is missing",
          CHECKBOX_MIN_LENGTH: "The customer contact checkbox text is missing",
          ADVICE_SCREEN_UNREACHABLE: "The Advice page is unreachable",
          ID_MIN_LENGTH: "The customer contact form identifier is missing",
          INVALID_PACKAGE_ADVICE:
            "Some settings in the package advice settings are invalid or incomplete",
        },
        questions: {
          TITLE_LENGTH: "A question is empty",
          ANSWER_COUNT: "A question has less than two answers",
          FLOW_CYCLE:
            "There is a loop back to an earlier point in your conversation.",
          MISSING_REFERENCE: "A question has a possible dead end",
          NO_QUESTIONS: "There are no questions",
          DRILLDOWN_ANSWER_COUNT: "A drill-down question has no answers",
          DRILLDOWN_UNCONFIGURED_FIELD:
            "A drill-down question has no catalogue field(s) configured",
          NUMERIC_ANSWER_MATCHING_LABEL_EMPTY:
            "The matching label of a numeric question is empty.",
        },
        answers: {
          TITLE_LENGTH: "An answer is empty",
        },
        rules: {
          MISSING_REFERENCE:
            "An exception directs to a question that no longer exists",
          MISSING_ANSWER:
            "An exception is based on an answer that no longer exists",
          MISSING_QUESTION:
            "An exception uses answers from a question that no longer exists",
          SKIPPED_ANSWER_ON_NON_OPTIONAL:
            "A question that cannot be skipped still contains a flow exception for a skip option.",
        },
        screens: {
          TITLE_LENGTH: "A info page header is empty",
          FLOW_CYCLE:
            "There is a loop back to an earlier point in your conversation.",
          SUBMITTED_MESSAGE_MIN_LENGTH:
            "The customer contact confirmation message is missing",
          CHECKBOX_MIN_LENGTH: "The customer contact checkbox text is missing",
          ID_MIN_LENGTH: "The customer contact form identifier is missing",
        },
        media: {
          MISSING_ANSWER_IMAGE: "An answer is missing an image",
          MISSING_QUESTION_IMAGE: "A question is missing an image",
          MISSING_QUESTION_VIDEO: "A question is missing an video",
        },
        lookAndFeel: {
          DEFAULT_STYLES: "You haven't set a look and feel yet",
        },
        catalogue: {
          CLASSIFICATION_EMPTY:
            "No {{productLabel}} in your catalogue have been fully matched to the answers",
          CLASSIFICATION_INCOMPLETE:
            "Some {{productLabel}} in your catalogue have not been fully matched to the answers",
          NO_PRODUCTS_FOUND: "No {{productLabel}} have been uploaded yet",
          MISSING_SECONDARY_SORT_COLUMN:
            "Please define how you want to sort products with the same score",
        },
      },
      navigation: {
        conversation: {
          title: "Conversation",
          intro:
            "You can reorder this list for easier access. This does not affect the actual flow of your conversation.",
          icon: ":speech_balloon:",
        },
        advice: { title: "Matching", icon: ":point_right:" },
        packageAdvice: { title: "Package advice" },
        catalogue: { title: "Catalogue", icon: ":package:" },
        catalogueUpdate: { title: "Update catalogue", icon: "" },
        catalogueNew: { title: "New catalogue", icon: "" },
        catalogueCreate: { title: "New catalogue", icon: "" },
        catalogueCreateShoppingFeed: {
          title: "New Google Shopping feed",
          icon: "",
        },
        integration: { title: "Integration", icon: ":vertical_traffic_light:" },
        lookAndFeel: { title: "Look and feel", icon: ":art:" },
        insights: { title: "Insights", icon: ":bar_chart:" },
        adviceScreen: { title: "Advice page", icon: ":point_right:" },
      },
    },
    integration: {
      publish: "Publish",
      update: "Update",
      copied: "Embed code copied!",
      status: {
        title: "Publish your app",
        offline: "This app is currently offline",
        online: "This app is live and was last updated",
      },
      embed: {
        title: "Embed your app",
        intro:
          "Embed your app with a couple lines of code. Pick your preferred integration option, then copy and paste the code into the HTML of the desired page on your website.",
        hide: "Hide embed code",
        show: "Show embed code",
      },
      release: {
        title: "Publish your app",
        titleReleased: "Update your app",
        intro: {
          publish: "Check out these notices before you publish your app:",
          update: "Check out these notices before you update your app:",
        },
        validations: {
          fix: "Fix in",
        },
        errors: {
          tooManyRequests: "Another one of your apps is being published. Please try again later.",
        },
        empty: "Good job! There are no unresolved notices for this app.",
        emptyReady: "Ready for launch 🚀? <0>{{link}}</0>.",
        emptyReadyLink: "Use our checklist for a good guided selling app",
        adjust: "Adjust the",
      },
      feedback: {
        published: "App published",
        updated: "Updated! It may take a moment for all changes to be visible.",
      },
    },
    lookAndFeel: {
      intro:
        "Style your app! Use the toggles to switch between screens, devices, and embed options.",
      form: {
        primary: "Main color",
        secondary: "Support color",
        headerColor: "Primary text color",
        text: "Secondary text color",
        background: "Background color",
        selectHover: "Selection and Hover",
        font: "Font",
        header: "Header Styling",
      },
      screens: {
        intro: "Start",
        info: "Info",
        question: "Question",
        advice: "Advice",
      },
      feedback: {
        disabledIntroScreen:
          "The start page has been disabled and won't be shown to the user",
      },
    },
    advisorSettings: {
      title: "App settings",
      advisor: {
        name: {
          label: "App name",
          placeholder: "Television app 2020",
          hint: "Name your app to identify it within Aiden",
        },
        product: {
          label: "We sell...",
          placeholder: "Televisions",
          hint: "Choose a word to describe the products or services in this app",
        },
        icon: "Change the icon",
        locale: {
          label: "App language",
          hint: "Changing the language does not update the language of the embedded buttons. You will have to update this on your website.",
          info: "Nearly every text in your app is defined by you. This language setting applies to a few texts the system defines for you, for example the ‘best match’ label and ‘show more’ buttons on the advice page.",
        },
        analyticsKey: {
          label: "Analytics parameter key",
          placeholder: "aiden-app",
          hint: "",
        },
        analyticsValue: {
          label: "Analytics parameter value",
          placeholder: "app name",
          hint: "",
        },
        failed: {
          error: "Something went wrong and we could not change these settings.",
          conflict: "There is already an app with this name.",
        },
      },
      feedback: {
        localeChanged:
          "Don’t forget to check the language of the embedded buttons on your website!",
      },
    },
    advisorCreate: {
      title: "Let's start with the basics.",
      subtitle: "What kind of app are you creating?",
      name: {
        label: "Name your app",
        placeholder: "TV Finder",
        hint: "Use an internal name that is clear to you and your co-creators.",
      },
      product: {
        label: "We sell...",
        placeholder: "Televisions",
        hint: "Choose one word to describe the products or services in this app.",
      },
      icon: "Select a matching icon",
      save: "Save",
      failed: {
        error: "Failed to create your app.",
        conflict: "An app with this name already exists.",
      },
    },
    analytics: {
      droffOffTooltip:
        "Find out how many users viewed, answered and did not answer each question. Keep in mind: due to different paths in the Conversation flow, the amount of views won't necessarily be sequential. The order of the questions in the table can also differ from the order in which your visitors see them in the app. So make sure to interpret the drop-off data for each question individually, not as a sequence!",
    },
    advisorDashboard: {
      navigation: {
        create: "Create",
        integrate: "Embed",
        analyzeImpact: "Impact",
        analyzeUsage: "Usage",
        analyzeQuestions: "Conversation",
      },
      analyze: {
        aggregates: {
          activity: "Activity",
          sessions: "Sessions",
          productClicks: "Click-through",
          clickRate: "Click-through rate",
          adviceViews: "Advice views",
        },
        periods: {
          last7days: "Last 7 days",
          last30days: "Last 30 days",
          lastMonth: "Previous month",
          last90days: "Last 90 days",
          last12months: "Last 12 months",
        },
      },
      conversation: {
        title: "Conversation",
        hint: "A great place to get started: set up your Q&A flow.",
        button: "Edit Q&A",
      },
      look_feel: {
        title: "Look & feel",
        hint: "Choose the colors and fonts that will make your app pop.",
        button: "Apply branding",
      },
      catalogue: {
        title: "Catalogue",
        hint: "Upload all the {{products}} you want to advise on.",
        button: "Edit catalogue",
      },
      advice: {
        title: "Advice",
        hint: "Match your conversation with your {{products}} to get your app up and running.",
        button: "Match {{products}}",
      },
      updateStatus: "Last updated {{timestamp}} by <0/> {{name}}",
      updateStatusYou: "Last updated {{timestamp}} by <0/> you",
    },
    advisorNavbar: {
      conversationFlow: "Conversation Flow",
      productmapping: "Productmapping",
      lookAndFeel: "Look & Feel",
      insights: "Insights",
    },
    advisorOverview: {
      metricsTitle: "App performance ",
      metricsTitleExtra: "(30 days)",
      title: "My Apps",
      add: "+ New App",
      live: "Live",
      info: "Last edited {{timestamp}}",
      options: {
        delete: "Delete",
        duplicate: "Duplicate",
      },
      hints: {
        duplicate:
          "This will create a copy of your app and copy over the Conversation, Look & Feel, Catalogue and their matching.",
      },
      more: "show more",
      empty: {
        cardTitle: "Your first app goes here",
        cardIntro: "Create a new app to get started",
      },
    },
    advisorFlow: {
      saved: "Conversation flow saved",
      saveError: "Something went wrong when saving...",
      unsavedChanges:
        "You have unsaved changes, are you sure you want to leave?",
      unsavedChangesConfirm: "Leave",
    },
    flowQuestionNav: {
      content: "Question",
      flow: "Flow",
      answers: "Answers",
      media: "Media",
    },
    flowEmptyState: {
      button: "Add question",
      cta: "start here",
    },
    screenEditor: {
      introTitle: "Start page",
      adviceTitle: "Advice page",
      introIntro:
        "Below you can set the content for the app's start page. Want to see what it looks like? Go to <0>{{link}}</0> or use the preview feature.",
      fields: {
        title: "Header",
        text: "Intro",
        additionalInformation: "Additional information",
        noResultsHelpText:
          "You can change the text that is shown to a customer when there are no results. If you leave these fields empty, a default text will be used.",
        noResultsTitle: "Header in case of empty advice",
        noResultsText: "Intro in case of empty advice",
        buttonText: "Start button",
        adviceButtonText: "Call to action button",
        showProductLinkText: "Show",
        actionType: "Choose the CTA type",
        customActionButtonText: "Custom action button",
        showCustomActionButtonText: "Show",
        customActionIdentifier: "Custom action identifier",
        resultSize: "Number of advised products",
        resultSizeLabel: "{{count}} product",
        resultSizeLabel_plural: "{{count}} products",
        skipIntro: "Show start page",
        skipIntroDesc:
          "Switch this off if you want to skip the start page and immediately show the next step.",
        sort: {
          title: "When products have the same score, sort them by...",
          emptyHelp:
            "After you've uploaded your product catalogue, you can select which property should be used to sort products with the same score.",
          sortByHelp:
            "Select a property from your product catalogue. This will be used to sort products with the same score.",
          sortOrderHelp: "Set the sorting order for the chosen property.",
          sortOrderDesc: "Highest to lowest",
          sortOrderAsc: "Lowest to highest",
          sortByNone: "Random",
          sortByInvalidColumn: "Select a property",
          info: "The Advice page shows the best matching products, sorted from high to low. For a certain answer combination, several products may end up with the exact same matching score. If so, the position of these products is decided at random. Would you rather sort products with the same score by, for example, popularity, price or margin? Simply use the dropdown to select your preferred sorting property. Please note that you can only select numerical properties from your data set.",
        },
        showBestMatchLabel: "Show",
        bestMatchLabel: "Label the highest ranking result",
        showMatchingIndicatorsLabel: "Show matching indicators",
        showPriceLabel: "Show price",
        showCurrencyLabel: "Show currency",
      },
      customerInfo: {
        // withResultsHelp: "Read more about <0>{{link}}</0>",
        hintStart:
          "Please note: these email settings will only work if you integrate the Aiden app with your CRM or CDP. Aiden does not send emails directly to your customers. Check the tutorial for more information.",
        showForm: "Ask for customer email address",
        showFormHelp:
          "Switch this on to allow customers to leave their email address on the advice page.",
        form: {
          title: "Header",
          intro: "Intro",
          introHelp:
            "Explain to the customer what to expect when they leave their contact details.",
        },
        ctaButtonLabel: "Call-to-action button",
        ctaButtonLabelHelp:
          'Set the text for the button that leads from the advice page to the customer contact form, for example "Email me the advice".',
        checkboxLabel: "Newsletter consent",
        showCheckbox: "Display opt-in",
        checkboxHelp:
          "Displays a checkbox with a custom text. Customers who leave their email address can check the box to sign up for your newsletter. If needed you can also link to your privacy policy.",
        formSubmittedMessage: "Form submitted message",
        formSubmittedMessageHelp:
          'Confirm to the customer that their email address has been received, and tell them what will happen next. For example: "Success! You’ll soon receive an email with the advice."',
        infoScreen: {
          showFormHelp:
            "Switch this on to allow customers to leave their email address on this page.",
          identifierLabel: "Identifier",
          identifierHelp:
            "This label can help you recognise from which info-page the form was sent.",
        },
      },
      showMatchingIndicatorsHelpText:
        "Switch this off to hide the check marks and crosses. These matching indicators are provided to explain how each product matches the answers provided by the user.",
      showPriceHelpText:
        "Switch this off to hide the price of the products on the advice page.",
      showCurrencyHelpText:
        "Switch this off to hide the price currency on the advice page.",
      bestMatchHelpText:
        "Shows a ‘best match’ label on the highest ranking result. If you prefer, you can enter a custom text for the label.",
      adviceButtonTextHelp:
        "This button is shown on each advised product and leads to the product page.",
      resultSizeHelp:
        "Set the number of products that is shown to a customer on the advice pag.e",
      customActionButtonTextHelp:
        "This button is shown on each advised product and triggers a JavaScript event which can be used for add to cart functionality.",
      customActionIdentifierHelp:
        "This identifier should be used to identify the event and handle it correctly",
    },
    questionsEditor: {
      back: "Question overview",
      helpText: "Info text",
      questionLabel: "Label",
      questionLabelHelp:
        "Pick a descriptive term to identify this question within Aiden. Optionally, select a color to group questions together or to highlight a question that still needs work.",
      screenLabelHelp:
        "Pick a descriptive term to identify this page within Aiden. Optionally, select a color to group questions and pages together.",
      type: "Question Type",
      titlePlaceholder: "e.g. 'What style do you prefer?'",
      helpTextPlaceholder: "Add a description to the question",
      types: {
        single: "Single answer",
        multiple: "Multiple answers",
        numeric: "Numerical answer",
        drilldown: "Drill-down",
        "auto-drilldown": "Auto Drill-down",
      },
      validations: {
        label: "The label should be between 3 and 24 characters long.",
      },
      feedback: {
        delete: {
          title: "Are you sure you want to delete this question?",
          explanation:
            "If you delete this question, the matching of the linked {{productLabel}} will also be removed.",
          questionImpact:
            "For this question there are {{count}} matched {{productLabel}}.",
          answerImpact:
            "For '{{answerLabel}}' there are {{count}} {{productLabel}}.",
          warning: "This cannot be undone.",
        },
      },
    },
    previewSidebar: {
      toggle: "Show a preview for this screen",
      empty: "Select a question or page to visually preview its contents.",
    },
    answersEditor: {
      helpText: "Press 'Enter' to add a new Answer option",
      placeholder: "Answer...",
      placeholderNoPref: "No preference...",
      addAnswer: "Add answer",
      unsavedChanges:
        "You have unsaved changes in an answer, these will be discarded if you proceed. Click 'Cancel' if you wish to review your changes first!",
      unsavedChangesConfirm: "Discard changes",
      addNeutralAnswer: "Add a 'no preference' answer",
      options: {
        extraInfo: "Add info text",
        matchingFeatureLabel: "Add a custom answer label",
        addRule: "Ask a different question when given this answer",
        delete: "Delete answer",
      },
      neutralAnswer: {
        tooltip: "This is a 'no preference' answer",
        hint1:
          "This question has a 'no preference' answer. Customers can pick this answer if the provided answers do not apply to them.",
        hint2Start: "The 'no preference' answer is automatically scored as a ",
        hint2Bold: "neutral match",
        hint2End:
          " for all products. In that sense, it’s exactly like skipping a question.",
      },
    },
    mediaEditor: {
      intro: "Enrich the question with visuals. Use our <0>{{link}}</0>.",
      introLink: "template",
      mediaTypeLabel: "Media type",
      options: {
        noMedia: "No media",
        answersWithImage: "Answers with image",
      },
    },
    answersWithImageEditor: {
      hint: {
        1: "The minimum image size is 560x300 pixels",
        2: "The maximum image size is 3000x3000 pixels",
        3: "Make sure to add an image to every answer",
      },
      feedback: {
        uploadFailure:
          "Something went wrong while uploading your image. Please try again.",
        tooSmall:
          "This image is too small. The minimum size required is {{desired}} pixels.",
      },
    },
    numericAnswerEditor: {
      intro:
        "You selected the question type 'numerical'. Instead of manually entering every possible answer to this question you can simply match the question to the relevant data point in <0>{{link}}</0>.",
      inputTypeLabel: "User input",
      infoTextLabel: "Add info text",
      infoTextHelpText:
        "This text is shown under the question and above the input field.",
      placeholderLabel: "Add input field label",
      placeholderHelpText: "This text is shown above the input field.",
      optionalLabel: "This question can be skipped",
      allowDecimalLabel: "Allow decimals",
      infoInputTextLabel: "Add unit of measurement",
      infoInputTextPlaceholder: "€, cm, kg",
      infoInputTextHelpText:
        "The unit of measurement can be shown before (prefix) or after (suffix) the amount that’s entered.",

      matchingFeature: {
        label: "Add a custom answer label",
        helpText:
          "The answer label is shown next to the matching indicators (check marks and crosses) that appear on each recommended product. For numerical questions, we’ll use the question label unless you enter a custom answer label here.",
      },
    },
    answerHelpTextEditor: {
      title: "Info text",
      helper:
        "This text is shown underneath the answer and can be used to provide more context (in max. 250 characters).",
    },
    matchingFeatureLabelEditor: {
      title: "Add a custom answer label",
      helper:
        "The answer label is shown next to the matching indicators (check marks and crosses) that appear on each recommended product. Use a custom answer label for answers that will lack context on the advice page (such as simple ‘yes or no’ answers).",
    },
    infoScreenRulesEditor: {
      base: {
        start: "The next step after",
        end: "is:",
      },
    },
    rulesEditor: {
      add: "Exception",
      blankAnswerLabelOption: "<Empty answer>",
      newQuestion: "New Question",
      newInfoPage: "New Info page",
      selectQuestion: "Select a question",
      adviseOption: "The advice",
      ifAnswer: "If the answer to",
      thenGo: "Then, go to",
      thenAsk: "Then, ask the question",
      selectAnswer: "Select an answer",
      title: "Rules",
      base: {
        start: "The next question after",
        middle: "is usually",
        end: "except for these exceptions:",
      },
    },
    logic: {
      is: "is",
      isNot: "is not",
      containsOnly: "only contains",
      containsOneOf: "contains one of",
      containsAll: "contains all of",
      equal: "equals",
      greaterThan: "is greater than",
      lesserThan: "is smaller than",
    },
    login: {
      loginBtn: "Login",
      forgotBtn: "Forgot password?",
      intro: "Log in with your Aiden account.",
      email: "Email",
      emailPlaceholder: "somebody@example.com",
      password: "Password",
      passwordPlaceholder: "Password",
      forgotLink: "Forgot password?",
      incorrect: "E-mail or password is incorrect",
      error: "Something went wrong, please try again",
      forgot: {
        intro: "Let's get you a new password",
        submit: "Request new password",
        backToLogin: "Back to login",
        successTitle: "Check your inbox",
        successMessage: {
          1: "You'll receive an email with instructions on how to reset your password.",
          2: "You can now close this page.",
        },
        error: "Something went wrong, please try again",
      },
      reset: {
        intro: "Choose a new password",
        submit: "Reset password",
        confirmPassword: "Confirm password",
        successTitle: "All done!",
        successMessage: {
          1: "You can now log in with your new password.",
          2: "Ready to create some awesome guided selling tools?",
        },
        successButton: "Go to login",
        error: {
          noCapital: "Password should contain at least one capital",
          noNumber: "Password should contain at least one number",
          noSpecialCharacter:
            "Password should contain at least one special character",
          invalidCharacter: "Password contains invalid character(s)",
          length: "Password should be at least 8 characters long",
          token: "Your reset request is invalid or expired.",
          invalid: "Your reset request is invalid or expired.",
          reset: "Request new password",
          other: "Something went wrong, please try again",
        },
      },
    },
    products: "product",
    products_plural: "{{count}} products",
    productsUpload: {
      title: "Upload product data",
      intro: "Select a CSV containing products to upload.",
      selectFile: "Select file",
    },
    productSubnav: {
      productData: "Catalogue",
      productMapping: "Mapping",
      productOverview: "Product overview",
    },
    productDataset: {
      feed: {
        navigation: {
          status: "Status",
          catalogue: "Catalogue",
        },
        status: {
          title: "Updates",
          updatingListItem:
            "Updating your catalogue of {{productLabel}} with data from your {{dataSourceLabel}}",
          updatingState: "Updating...",
          hints: {
            updateFeedURL:
              "Do you want to change the URL of your feed? Then delete your current catalog. Note: your existing matching will be lost.",
          },
          actions: {
            update: "Update",
            viewUpdateHistory: "View update history",
          },
        },
        history: {
          title: "Update history",
          empty: "No updates yet!",
        },
        setup: {
          title: "Add a feed with your product data",
          select: "How would you like to import your catalogue?",
          actions: {
            import: "Import",
          },
          feedback: {
            issuesTitle: "This is the issue we found in your product data:",
            issuesTitle_plural:
              "These are the issues we found in your product data:",
            invalid:
              "The URL entered is not a valid Google Shopping feed or is currently unavailable.",
            TECHNICAL_ERROR:
              "Could not update your data from the feed, please try again.",
            EMPTY_FEED:
              "Your feed is empty. Please check the URL of your feed.",
            UNAVAILABLE_FEED:
              "Your feed is unavailable. Please check the URL of your feed.",
            INVALID_FEED:
              "Your feed is not a valid Google Shopping feed. Please check your feed.",
            MISSING_ID_FIELD:
              "Your feed does not contain the required Google Shopping fields. The ID field is missing for at least one product.",
            MISSING:
              'The following products are missing a value in the required {{dataSourceLabel}} field "{{column}}":',
            multipleErrors:
              "It looks like this is not a valid Google Shopping feed. Please check the details below.",
            andMoreProducts: "and {{numProducts}} more...",
            resolve: {
              intro: "To resolve this issue, you can:",
              intro_plural: "To resolve these issues, you can:",
              step1: "check the issue below",
              step1_plural: "check the issues below",
              step2: "edit the data in your data source or feed manager",
              step3: "then return to this page and import the feed again",
            },
          },
        },
        item: {
          header: {
            success:
              "We've checked <strong>{{total}}</strong> {{productLabel}}",
            warning:
              "{{amount}} of <strong>{{total}}</strong> {{productLabel}} could not be updated",
          },
          added: "{{amount}} added",
          removed: "{{amount}} removed",
          updated: "{{amount}} updated",
          failed: "{{amount}} failed",
          columnsAdded: "Columns added:",
          columnsRemoved: "Columns removed:",
          feedback: {
            rateLimitReached:
              "You've reached the maximum number of updates per hour, please try again later!",
            intro:
              'There was a problem updating "{{column}}" for the following products.',
            expectedActual:
              "A <strong>{{expected}}</strong> was expected but it contained <strong>{{actual}}</strong>:",
            dataTypesExpected: {
              TEXT: "textual value",
              NUMBER: "numerical value",
            },
            dataTypesActual: {
              TEXT: "text",
              NUMBER: "numbers",
            },
            dataSource: {
              googleShoppingFeed: "Google Shopping feed",
              csv: "CSV",
            },
            MISSING:
              'The following products were missing a value in the required {{dataSourceLabel}} field "{{column}}":',
            INVALID:
              'Some products contained invalid values for the field "{{column}}" and were not updated:',
            TECHNICAL_ERROR:
              "Could not update your data from the feed, please try again.",
            EMPTY_FEED: "Your feed is empty. Check the URL of your feed.",
            UNAVAILABLE_FEED:
              "Your feed is unavailable. Check the URL of your feed.",
            INVALID_FEED:
              "Your feed does not contain the required Google Shopping categories. Check your feed.",
            unknownError:
              "Could not update your data from the feed, please try again.",
            resolve: {
              intro: "To resolve this issue, you can:",
              intro_plural: "To resolve these issues, you can:",
              step1: "check the issues below",
              step2: "edit the data in your data source or feed manager",
              step3: "then return to Aiden and update your catalogue",
            },
          },
        },
      },
      empty: {
        title: "Upload your {{productLabel}}",
        intro1: "Start setting up your catalogue by selecting an import type.",
        actions: {
          setup: "Set up catalogue",
        },
      },
      setup: {
        title: "Set up catalogue",
        select: "Select your import type.",
      },
      select: "How would you like to import your catalogue?",
      title: "Your catalogue of {{productLabel}}",
      button: "Create dataset",
      deleteButton: "Delete catalogue",
      delete: {
        title: "Are you sure you want to delete this catalogue?",
        intro: "All data and matching with your conversation will be lost.",
      },
      show: {
        intro: "The product catalogue for {{name}}.",
        fileName: "Last file uploaded:",
        updatedAt: "Last updated {{timestamp}}",
        columnMapping: "Column mapping",
        formats: {
          currency: "Price currency",
        },
      },
      create: {
        intro: {
          1: "Creating a successful app isn't possible without proper data!",
          2: "Start by creating a dataset for {{name}}",
        },
        step1: {
          title: "Select your data file",
          progressLabel: "Select",
          info: {
            1: "Make sure the first row of your CSV file contains the headers.",
            2: "Your data set must contain specific properties so that it can function within Aiden. Required are:",
            3: "A unique identifier, like a product ID, SKU or EAN.",
            4: "The name of the product.",
            5: "A link to the product in the form of a URL.",
            6: "A link to an image in the form of a URL. Valid image formats are .jpeg, .jpg, .png or .webp.",
            7: "You can define which columns to use for these properties in the next step.",
          },
        },
        step2: {
          title: "Define required columns",
          selectColumn: "Select column",
          intro:
            "We need to know a little about your catalogue to properly integrate it into your app. Tell us which of the following properties map to columns in your catalogue?",
          progressLabel: "Define",
          labels: {
            id: "ID",
            name: "Product name",
            url: "URL",
            image: "Image",
            price: "Price (optional)",
            currency: "Price currency",
          },
          explanation: {
            id: "An unique identifier that can be used to identify a product, like an SKU.",
            name: "The name of the product",
            url: "The link to the product. This link is used to send customers from the app to the recommended products.",
            image: "A link to the image of the product.",
            price: "The price of the product.",
            currency: "The currency of the price.",
          },
          validationMessages: {
            id: "The column you have selected contains data that does not conform to the required format. An 'id' should have at least 1 character.",
            name: "The column you have selected contains data that does not conform to the required format. An 'name' should have at least 1 character.",
            url: "The column you have selected contains data that does not conform to the required format. An 'url' should start with http.",
            image:
              "The column you have selected contains data that does not conform to the required format. An 'image' should start with http.",
            price:
              "The column you have selected contains data that does not conform to the required format. An 'price' may contain a $,€ of £ and can only contain numbers, comma's and periods and can optionally end with an '-'.",
          },
        },
        step3: {
          title: "Uploading file...",
          progressLabel: "Upload",
          uploading: "We're processing your data, this might take a while!",
          done: "All done. Now let’s start matching these {{productLabel}} to your conversation.",
          error:
            "Oh no, something went wrong! Please try to upload the data again.",
          button: "Start matching!",
          errorButton: "Try again",
        },
      },
      update: {
        step1: {
          title: "Select a file with new data",
          progressLabel: "Select",
          info: {
            1: "Make sure the updated dataset contains the same columns as before. Column names are case sensitive.",
          },
          validationMessage:
            "The selected datafile is missing the following required columns: ",
        },
        step2: {
          title: "Review changes",
          progressLabel: "Review",
          info: {
            1: "Changes compared to your last data file:",
            2: "There are {{count}} new products found, which will be added to your dataset. You can map afterwards.",
            3: "There are {{count}} existing products, which will be updated with new data from your file.",
            4: "{{count}} products are no longer present in your dataset, these will be removed from your dataset and will no longer show up on the advice page.",
          },
        },
        step3: {
          title: "Uploading updated file...",
          progressLabel: "Upload",
          uploading:
            "We're processing your updated data, this might take a while!",
          done: "All done. Now let's make sure all of these (new) {{productLabel}} are matched to your conversation.",
          error:
            "Oh no, something went wrong! Please try to upload the updated dataset again.",
          button: "Start matching!",
          errorButton: "Try again",
        },
      },
    },
    advice: {
      title: "Define which customers will get which advice",
      intro:
        "The combination of answers given by a customer will determine which {{productLabel}} best match their needs.",
      howDoesItWork: "How does it work?",
      introStart: "Start matching by selecting a question!",
      distribution:
        "Below you can see the current distribution of {{productLabel}} per answer. For the best advice, make sure all {{productLabel}} are matched to all answers!",
      hints: {
        matchingFilter: "Treat this question as a filter",
        matchingFilterTooltip:
          "By activating this toggle, products classified as a ‘bad match' (X) will not be shown in the advice. Use this, for example, if you only want to recommend products that meet the size requirement a customer has specified. After all, other sizes would not be relevant for that particular customer.",
      },
      questions: {
        empty: "Select a question ({{count}} unmatched)",
        unmatchedEmpty: "all done!",
        unmatched: "{{count}} unmatched",
      },
      numericMatching: {
        intro:
          "This is a numerical question. Users will answer it by entering a number. Matching just takes 2 steps:",
        step1:
          "First, select the matching numerical column from your Catalogue. We’ve already provided our best guess!",
        step2:
          "Next, define whether the value entered by the user should be seen as an exact match, a range, a minimum or a maximum:",
        columnLabel: "Column linked to the question",
        matchLogicLabel:
          "Products are a match when their value in '{{column}}'",
        bandwidthLabel: "Range",
        matchLogicOptions: {
          equals: "Matches the user's answer exactly",
          greaterThan: "Is equal to or greater than the user's answer",
          smallerThan: "Is equal to or smaller than the user's answer",
          bandwidth: "Is within a certain range of the user's answer",
        },
        matchLogicOrientation: {
          start:
            "Any product with a '{{column}}' value that is equal to or within {{limit}} above the user's answer will be considered a perfect match.",
          around:
            "Any product with a '{{column}}' value that is within {{limit}} above or below the user's answer will be considered a perfect match.",
          end: "Any product with a '{{column}}' value that is equal to or within {{limit}} below the user's answer will be considered a perfect match.",
        },
      },
      matching: {
        bad: "Bad match",
        neutral: "Neutral match",
        good: "Good match",
        perfect: "Perfect match",
        unmatched: "Unmatched",
      },
      errors: {
        fetchFailed:
          "Something went wrong while trying to update the product view.",
      },

      explanation: {
        title: "Let's play matchmaker",
        intro:
          "After a customer has completed the Q&A, they will receive an advice on which {{productLabel}} best match their needs. To make sure every customer gets the right advice, all of your {{productLabel}} need to be matched to every answer in the conversation.",
        matchIntro:
          "For every combination of {{productLabel}} and answers, you can define if it’s a…:",
        outro:
          "Ultimately, the advice a customer sees is determined by the combination of answers they gave. So, {{productLabel}} that are a perfect match on one answer, may still not show up in the advice if they didn’t match a customer’s other needs.",
        outro2:
          "For the best results, make sure all of your {{productLabel}} are matched to every answer in the conversation. And don’t forget to play around and test, test, test!",
        neutral: {
          start: "Neutral match.",
          text: "These {{productLabel}} are neither a bad, nor a good fit for this answer. They are not more or less likely to be advised if a customer gives this answer. ",
        },
        match: {
          start: "Good match.",
          text: "These {{productLabel}} are a fine fit for the answer. They are slightly more likely to be advised if a customer gives this answer.",
        },
        noMatch: {
          start: "Bad match.",
          text: "These {{productLabel}} are a bad fit for the answer. They are less likely to be advised if a customer gives this answer.",
        },
        perfect: {
          start: "Perfect match.",
          text: "These {{productLabel}} are an excellent fit for the answer. They are much more likely to be advised if a customer gives this answer.",
        },
      },
    },
    productMapping: {
      label:
        "Determine the suitability of the products for answers related to the question:",
      selected: "selected",
      found: "found",
      products: "{{count}} product",
      products_plural: "{{count}} products",
      productsSelected: "{{selected}} of {{count}} products",
      selectAll: "Select all products",
      clear: "Clear selection",
      emptyState: {
        text: "You haven't uploaded any products yet!",
        button: "Upload product data",
      },
      emptyQuestions: {
        text: "You don't have any questions yet!",
        button: "Create a conversation",
      },
      emptyAnswers: {
        text: "This question doesn't have any answers yet!",
        button: "Add answers to this question",
      },
      filters: {
        addCatalogueFilter: "Add catalogue filter",
        addQuestionFilter: "Add question filter",
        label: "Filter your products",
        clear: "clear filters",
        invalid:
          "Some filters are incomplete. Finish or remove them to filter your products.",
        columnPlaceholder: "Search column name",
        valuePlaceholder: "Search property",
        selectColumn: "Select:",
        selectProperty: "Select a property",
        selection: {
          joinLabelRange: "to",
          joinLabelMulti: "or",
        },
        types: {
          number: {
            fromLabel: "From:",
            toLabel: "To:",
          },
        },
      },
    },
    components: {
      pagination: {
        perPage: "{{count}} per page",
        range: "{{start}} - {{end}} of {{max}}",
      },
      advisorSidebar: {
        overview: "Overview",
        introExplanation: "Configure the start page",
        adviseExplanation: "Configure the advice page",
        collapse: "Collapse",
      },
    },
    locales: {
      "en-US": "English (United States)",
      "en-GB": "English (United Kingdom)",
      nl: "Dutch",
      da: "Danish",
      de: "German",
      fr: "French",
      it: "Italian",
      es: "Spanish",
      fi: "Finnish",
      no: "Norwegian",
      sv: "Swedish",
      pl: "Polish",
      pt: "Portuguese",
    },
    currencies: {
      USD: "US dollar",
      EUR: "Euro",
      JPY: "Japanese yen",
      GBP: "Pound sterling",
      AED: "United Arab Emirates dirham",
      AFN: "Afghan afghani",
      ALL: "Albanian lek",
      AMD: "Armenian dram",
      ANG: "Netherlands Antillean guilder",
      AOA: "Angolan kwanza",
      ARS: "Argentine peso",
      AUD: "Australian dollar",
      AWG: "Aruban florin",
      AZN: "Azerbaijani manat",
      BAM: "Bosnia and Herzegovina convertible mark",
      BBD: "Barbadian dollar",
      BDT: "Bangladeshi taka",
      BGN: "Bulgarian lev",
      BHD: "Bahraini dinar",
      BIF: "Burundian franc",
      BMD: "Bermudian dollar",
      BND: "Brunei dollar",
      BOB: "Bolivian boliviano",
      BRL: "Brazilian real",
      BSD: "Bahamian dollar",
      BTN: "Bhutanese ngultrum",
      BWP: "Botswana pula",
      BYN: "Belarusian ruble",
      BZD: "Belize dollar",
      CAD: "Canadian dollar",
      CDF: "Congolese franc",
      CHF: "Swiss franc",
      CLP: "Chilean peso",
      CNY: "Chinese yuan",
      COP: "Colombian peso",
      CRC: "Costa Rican colón",
      CUC: "Cuban convertible peso",
      CUP: "Cuban peso",
      CVE: "Cape Verdean escudo",
      CZK: "Czech koruna",
      DJF: "Djiboutian franc",
      DKK: "Danish krone",
      DOP: "Dominican peso",
      DZD: "Algerian dinar",
      EGP: "Egyptian pound",
      ERN: "Eritrean nakfa",
      ETB: "Ethiopian birr",
      FJD: "Fijian dollar",
      FKP: "Falkland Islands pound",
      GEL: "Georgian lari",
      GGP: "Guernsey pound",
      GHS: "Ghanaian cedi",
      GIP: "Gibraltar pound",
      GMD: "Gambian dalasi",
      GNF: "Guinean franc",
      GTQ: "Guatemalan quetzal",
      GYD: "Guyanese dollar",
      HNL: "Honduran lempira",
      HKD: "Hong Kong dollar",
      HRK: "Croatian kuna",
      HTG: "Haitian gourde",
      HUF: "Hungarian forint",
      IDR: "Indonesian rupiah",
      ILS: "Israeli new shekel",
      IMP: "Manx pound",
      INR: "Indian rupee",
      IQD: "Iraqi dinar",
      IRR: "Iranian rial",
      ISK: "Icelandic króna",
      JEP: "Jersey pound",
      JMD: "Jamaican dollar",
      JOD: "Jordanian dinar",
      KES: "Kenyan shilling",
      KGS: "Kyrgyzstani som",
      KHR: "Cambodian riel",
      KID: "Kiribati dollar",
      KMF: "Comorian franc",
      KPW: "North Korean won",
      KRW: "South Korean won",
      KWD: "Kuwaiti dinar",
      KYD: "Cayman Islands dollar",
      KZT: "Kazakhstani tenge",
      LAK: "Lao kip",
      LBP: "Lebanese pound",
      LKR: "Sri Lankan rupee",
      LRD: "Liberian dollar",
      LSL: "Lesotho loti",
      LYD: "Libyan dinar",
      MAD: "Moroccan dirham",
      MDL: "Moldovan leu",
      MGA: "Malagasy ariary",
      MKD: "Macedonian denar",
      MMK: "Burmese kyat",
      MNT: "Mongolian tögrög",
      MOP: "Macanese pataca",
      MRU: "Mauritanian ouguiya",
      MUR: "Mauritian rupee",
      MVR: "Maldivian rufiyaa",
      MWK: "Malawian kwacha",
      MXN: "Mexican peso",
      MYR: "Malaysian ringgit",
      MZN: "Mozambican metical",
      NAD: "Namibian dollar",
      NGN: "Nigerian naira",
      NIO: "Nicaraguan córdoba",
      NOK: "Norwegian krone",
      NPR: "Nepalese rupee",
      NZD: "New Zealand dollar",
      OMR: "Omani rial",
      PAB: "Panamanian balboa",
      PEN: "Peruvian sol",
      PGK: "Papua New Guinean kina",
      PHP: "Philippine peso",
      PKR: "Pakistani rupee",
      PLN: "Polish złoty",
      PRB: "Transnistrian ruble",
      PYG: "Paraguayan guaraní",
      QAR: "Qatari riyal",
      RON: "Romanian leu",
      RSD: "Serbian dinar",
      RUB: "Russian ruble",
      RWF: "Rwandan franc",
      SAR: "Saudi riyal",
      SEK: "Swedish krona",
      SGD: "Singapore dollar",
      SHP: "Saint Helena pound",
      SLL: "Sierra Leonean leone",
      SLS: "Somaliland shilling",
      SOS: "Somali shilling",
      SRD: "Surinamese dollar",
      SSP: "South Sudanese pound",
      STN: "São Tomé and Príncipe dobra",
      SYP: "Syrian pound",
      SZL: "Swazi lilangeni",
      THB: "Thai baht",
      TJS: "Tajikistani somoni",
      TMT: "Turkmenistan manat",
      TND: "Tunisian dinar",
      TOP: "Tongan paʻanga",
      TRY: "Turkish lira",
      TTD: "Trinidad and Tobago dollar",
      TVD: "Tuvaluan dollar",
      TWD: "New Taiwan dollar",
      TZS: "Tanzanian shilling",
      UAH: "Ukrainian hryvnia",
      UGX: "Ugandan shilling",
      UYU: "Uruguayan peso",
      UZS: "Uzbekistani soʻm",
      VES: "Venezuelan bolívar soberano",
      VND: "Vietnamese đồng",
      VUV: "Vanuatu vatu",
      WST: "Samoan tālā",
      XAF: "Central African CFA franc",
      XCD: "Eastern Caribbean dollar",
      XOF: "West African CFA franc",
      XPF: "CFP franc",
      ZAR: "South African rand",
      ZMW: "Zambian kwacha",
      ZWB: "Zimbabwean bonds",
    },
  },
}
