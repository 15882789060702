<script lang="ts">
  import previewImg from "./preview-product-img.jpg"
  import type { Page as PageT } from "../../app/components/page/toPage"
  import Page from "../../app/components/page/Page.svelte"

  import { type Recommendation as RecommendationT } from "../../app/components/page/toPage"
  export let cardCount = 3

  const recommendation: RecommendationT = {
    productName: "Product name",
    imageUrl: previewImg,
    productUrl: "https://",
    price: "€ 12,-",
    salePrice: {
      previousPriceAnnotation: "",
      salePeriodEnd: undefined,
      salePeriodStart: undefined,
      price: "€ 5,-",
    },
    state: undefined,
    recommendationContext: {
      recommendationPosition: 0,
      productContext: "RECOMMENDATION",
    },
    productId: "product-id",
    clientProductId: "",
    isProductCheckProduct: false,
    productData: {},
    specification: {
      price: null,
    },
    traceability: [
      {
        score: "bad",
        answer: "Some answer",
      },
      {
        score: "good",
        answer: "Some answer",
      },
      {
        score: "perfect",
        answer: "Some answer",
      },
    ],
    buttonCtaText: "",
    energyLabelField: undefined,
    additionalFields: [
      {
        label: "Label",
        suffix: "",
        values: ["Magna Lorem Vestibulum Ligula"],
      },
    ],
    pricePrefix: "",
    priceSuffix: "",
    variants: [],
    reviewScore: 3.5,
    reviewCount: 12345,
    display: {
      bestMatch: true,
      traceability: true,
      price: true,
      productCta: true,
      ctaType: "ProductLink",
    },
  }

  export let page: Extract<PageT, { type: "advice-page" }> = {
    type: "advice-page",
    screenId: "string",
    title: "Advice page title",
    text: "Advice page intro text. Ipsum Mattis Nibh Euismod Tortor [with a link.]()",
    additionalText: "",
    recommendations: [recommendation],
    customerInfoForm: {
      title: "",
      enabled: false,
      showCheckbox: false,
      checkboxLabel: "",
      formSubmittedMessage: "",
      ctaButtonLabel: "",
      intro: ""
    },
    actions: []
  }
  
</script>

<Page
  {page}
  isPreview
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
  hidePrev
></Page>
