<svelte:options customElement="aiden-answer-option" />

<script lang="ts">
  import AnswerOption from "../ui/AnswerOption.svelte"
  import Image from "../app/components/media/Image.svelte"
  export let text: string = ""
  export let image: string = ""
  export let type: string = "single"
  export let selected: string = "false"

  let isSelected = false
  $: isSelected = selected === "true"
</script>

<div>
  <AnswerOption
    onClick={() => {}}
    option={{
      label: "Label",
      helpText: "",
      image: "",
      value: "",
    }}
    {isSelected}
    type={type === "multi" ? "multi" : "single"}
  >
    <span slot="image">
      {#if image}
        <Image alt="" src={image} />
      {/if}
    </span>
    {#if text}
      {text}
    {/if}
  </AnswerOption>
</div>

<style>
  div :global(*) {
    cursor: default !important;
  }
</style>
