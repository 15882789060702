import React, { useEffect, useState } from "react"
import useNavigation from "~/hooks/useNavigation"
import ConversationsCanvas from "./ConversationsCanvas"
import SidebarContent from "./Sidebar"
import { Advisor } from "~/store/advisors"
import useQuestions from "~/hooks/useQuestions"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { useSelector } from "react-redux"
import { ApplicationState } from "~/store"
import { requestStatus } from "~/store/requestStatus/selectors"
import { Status } from "~/store/requestStatus/reducer"
import { hasChanges } from "~/store/flowChangeTracker/selectors"
import { useTranslation } from "react-i18next"
import NavPrompt from "~/components/NavPrompt"
import AutoSaveStatus from "./AutoSaveStatus"
import { SidebarToggle } from "~/components/Layout"
import { useQandASidebarContext } from "./QandASidebarContext"
import ChevronBack from "~/components/icons/ChevronBack"
import useAdvisors from "~/hooks/useAdvisors"
import useMountEffect from "~/hooks/useMountEffect"
import { isAdvisorLoading } from "~/store/advisors/selectors"
import LoadingSpinner from "~/components/LoadingSpinner"
import { AdvisorValidation } from "./AdvisorValidation"
import useQandARefresh from "~/hooks/useQandARefresh"
import { ScreenPreview } from "./previews/ScreenPreview"
import { QuestionPreview } from "./previews/QuestionPreview"
import Eye from "~/components/icons/Eye"
import { Cross } from "~/components/icons"
import { StyleSheetCSSVariables } from "~/elm-tsx-wrappers/StyleSheetCSSVariables"
import { EmptyPreview } from "./previews/EmptyPreview"

const Flow: React.FC<{ advisor: Advisor }> = (props) => {
  const { advisor } = props
  const { map } = useNavigation()
  const { addNewQuestion, removeQuestion } = useQuestions(advisor.id)
  const { getAdvisor } = useAdvisors()
  useMountEffect(() => getAdvisor(advisor.id))
  // const { saveAdvisor } = useAdvisors()
  const [requestId, setRequestId] = useState("")
  const [previewErrorMessage, setPreviewErrorMessage] = useState("")
  const { t } = useTranslation()
  const status = useSelector((state: ApplicationState) =>
    requestStatus(state, requestId)
  )
  const flowWasChanged = useSelector((state: ApplicationState) =>
    hasChanges(state, advisor.id)
  )
  const { url } = useRouteMatch()
  const match = useRouteMatch<{ questionId?: string }>(
    `${url}/questions/:questionId`
  )

  const selectedQuestionId: string | undefined = match?.params.questionId

  const screenMatch = useRouteMatch<{ screenId?: string }>(
    `${url}/screens/:screenId`
  )
  const selectedScreenId: string | undefined = screenMatch?.params.screenId

  useQandARefresh(advisor.id)

  useEffect(() => {
    let timeout
    if (status?.type !== Status.PENDING)
      timeout = setTimeout(() => setRequestId(""), 2000)
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [status])

  const {
    collapsed,
    isPreviewCollapsed,
    changeCollapse,
    changePreviewCollapse,
  } = useQandASidebarContext()

  const isRefreshing = useSelector((state: ApplicationState) =>
    isAdvisorLoading(state, advisor.id)
  )

  const toggleSidebar = () => {
    changeCollapse(!collapsed)

    if (!collapsed && !isPreviewCollapsed) {
      changePreviewCollapse(true)
    }
  }

  useEffect(() => {
    if (!selectedQuestionId && !selectedScreenId && !isPreviewCollapsed) {
      setPreviewErrorMessage(t("previewSidebar.error.message"))
    } else if (previewErrorMessage) {
      setPreviewErrorMessage("")
    }
  })

  return (
    <>
      <NavPrompt
        pathSection={"/flow"}
        extraCondition={() => flowWasChanged}
        leftButtonLabel={t("advisorFlow.unsavedChangesConfirm")}
      >
        <p>{t("advisorFlow.unsavedChanges")}</p>
      </NavPrompt>
      <div
        className={`conversation--layout
          ${collapsed ? "--is-collapsed" : ""}
          ${!isPreviewCollapsed ? "--is-previewing" : ""}`}
      >
        {isRefreshing ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              placeContent: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: 109,
              top: 0,
              right: 0,
              left: 0,
              background: "rgba(255,255,255,0.8)",
            }}
          >
            <LoadingSpinner />
          </div>
        ) : null}

        <div className="conversation--canvas">
          <ConversationsCanvas
            advisorId={advisor.id}
            selectedQuestionId={selectedQuestionId}
            selectedScreenId={selectedScreenId}
            questionIds={advisor.questions}
            addQuestion={addNewQuestion}
            removeQuestion={removeQuestion}
          />
        </div>
        <div className="conversation--sidebar-editor">
          <SidebarToggle
            onRight={true}
            open={!collapsed}
            toggle={toggleSidebar}
          />
          <button
            disabled={(!selectedQuestionId && !selectedScreenId) || collapsed}
            title={t("previewSidebar.toggle")}
            id="open-preview"
            className="visual-preview-toggle"
            onClick={() => changePreviewCollapse(!isPreviewCollapsed)}
          >
            <span className="icon" style={{transform: "translateY(-1px)"}}>
              {isPreviewCollapsed ? <Eye /> : <Cross />}
            </span>
          </button>
          <div
            className="conversation--sidebar-editor-content"
            data-test-id="sidebar"
            id="sidebar"
          >
            <div className="layout__main-actions-bar">
              <Switch>
                <Route
                  path={`${url}/*`}
                  exact={false}
                  render={(match) => (
                    <Link
                      to={map.conversation(advisor.id)}
                      className="link"
                      style={{
                        display: "inline-block",
                        marginRight: "auto",
                        fontSize: ".875rem",
                      }}
                    >
                      <ChevronBack />
                      {t("Back")}
                    </Link>
                  )}
                />
              </Switch>

              <AutoSaveStatus advisorId={advisor.id} />
              <AdvisorValidation />
            </div>
            <SidebarContent advisorId={advisor.id} />
          </div>
        </div>
        <div className="conversation--sidebar-preview">
          {advisor?.styleTemplateId ? (
            <StyleSheetCSSVariables styleTemplateId={advisor.styleTemplateId} />
          ) : null}
          <div
            className="sidebar-preview"
            style={{
              height: "100%",
              display: "grid",
              gridTemplateRows: "min-content 1fr",
            }}
          >
            {selectedScreenId ? (
              <ScreenPreview advisor={advisor} screenId={selectedScreenId} />
            ) : selectedQuestionId ? (
              <QuestionPreview questionId={selectedQuestionId} />
            ) : (
              <EmptyPreview />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Flow
