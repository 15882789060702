<script lang="ts">
  import type { PredefinedOption } from "../app/components/page/toPage"
  import CheckBox from "./CheckBox.svelte"
  import Chevron from "./Chevron.svelte"
  export let isSelected: boolean = false
  export let option: PredefinedOption
  export let type: "single" | "multi"
  export let onClick: (option: PredefinedOption) => void

  const { value, label } = option

  const labelId = `label-for-${value}`
</script>

{#if type === "single"}
  <button
    tabindex="0"
    class="answer-option"
    on:click={() => onClick(option)}
    class:is-selected={isSelected}
  >
    {#if $$slots.image}
      <div class="image">
        <slot name="image"></slot>
      </div>
    {/if}
    <div class="content">
      <div>
        <span id={labelId} class="answer-option--title">
          {label}
        </span>
        {#if $$slots.default}
          <div class="answer-option--text">
            <slot></slot>
          </div>
        {/if}
      </div>
      <div class="indicator">
        <Chevron aria-hidden="true" />
      </div>
    </div>
  </button>
{:else if type === "multi"}
  <div class="answer-option multi-answer-option">
    <input
      tabindex="0"
      type="checkbox"
      role="checkbox"
      checked={isSelected}
      aria-checked={isSelected}
      aria-required={true}
      {value}
      id={labelId}
      class="screenreader-only"
      on:click={() => onClick(option)}
    />
    <label
      for={labelId}
      class="answer-option-label"
      class:is-selected={isSelected}
      aria-labelledby={`label-for-${value}`}
    >
      {#if $$slots.image}
        <div class="image">
          <slot name="image"></slot>
        </div>
      {/if}
      <div class="content">
        <div>
          <span id={`label-for-${value}`} class="answer-option--title">
            {label}
          </span>
          {#if $$slots.default}
            <div class="answer-option--text">
              <slot></slot>
            </div>
          {/if}
        </div>
        <div class="indicator">
          <CheckBox aria-hidden="true" checked={isSelected} />
        </div>
      </div>
    </label>
  </div>
{/if}

<style>
  .answer-option {
    /* var(--aiden-answer-option-indicator-color, #000); */
    --aiden-checkbox-border-color: var(
      --aiden-answer-option-checkbox-border-color,
      #000
    );
    --aiden-checkbox-background-color: var(
      --aiden-answer-option-checkbox-background-color,
      #ebebeb
    );
    --aiden-checkbox-check-color: transparent;
    --aiden-chevron-color: var(--aiden-answer-option-chevron-color, #000);
    border: clamp(1px, var(--aiden-answer-option-border-width, 1px), 4px) solid
      var(--aiden-answer-option-border-color, #dedede);
    background-color: var(--aiden-answer-option-background-color);
    border-radius: var(--aiden-answer-option-border-radius, 1rem);
    color: var(--aiden-answer-option-text-color, #000);
    overflow: hidden;
    width: 100%;
    font-family: inherit;
    text-align: left;
    font-size: inherit;
    padding: 0;
    transition: 150ms all ease-out;
    cursor: pointer;

    & .answer-option-label {
      cursor: pointer;
    }
  }

  .answer-option.multi-answer-option {
    display: grid;
    grid-template-rows: 1fr;
  }

  /* .answer-option.has-image {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  @container (min-width: 560px) {
    .answer-option.has-image {
      max-width: 560px;
      margin-left: none;
      margin-right: none;
    }
  } */
  .content {
    display: grid;
    grid-template-columns: 1fr 1.5rem;
    height: auto;
    padding: 1rem;
    gap: 0.5rem;
    font-family: inherit;
    font-size: inherit;
    align-items: start;
    text-align: left;
  }

  @media (hover: hover) {
    .answer-option:hover {
      --aiden-checkbox-border-color: var(
        --aiden-answer-option-checkbox-border-color-selected,
        #000
      );
      --aiden-checkbox-border-color-checked: var(
        --aiden-answer-option-checkbox-border-color-selected,
        #000
      );
      --aiden-checkbox-background-color-checked: var(
        --aiden-answer-option-checkbox-background-color-selected,
        #000
      );

      --aiden-checkbox-check-color-checked: var(
        --aiden-answer-option-checkbox-color-selected,
        #fff
      );

      --aiden-chevron-color: var(
        --aiden-answer-option-chevron-color-selected,
        #000
      );

      border-color: var(--aiden-answer-option-border-color-selected, #000);
    }
  }
  .answer-option:active,
  .answer-option:focus-visible,
  .answer-option.is-selected,
  .answer-option:has(input:focus-visible) {
    --aiden-checkbox-border-color: var(
      --aiden-answer-option-checkbox-border-color-selected,
      #000
    );
    --aiden-checkbox-border-color-checked: var(
      --aiden-answer-option-checkbox-border-color-selected,
      #000
    );
    --aiden-checkbox-background-color-checked: var(
      --aiden-answer-option-checkbox-background-color-selected,
      #000
    );

    --aiden-checkbox-check-color-checked: var(
      --aiden-answer-option-checkbox-color-selected,
      #fff
    );

    --aiden-chevron-color: var(
      --aiden-answer-option-chevron-color-selected,
      #000
    );

    border-color: var(--aiden-answer-option-border-color-selected, #000);
  }

  .answer-option:has(input:focus-visible) {
    outline: Highlight auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }

  .answer-option--title {
    display: flex;
    align-items: center;
    min-height: 24px;
    color: var(--aiden-answer-option-text-color, #000);
    font-family: var(--aiden-answer-option-font-family, inherit);
    /* --aiden-regular-text-font-weight: 600; */
    font-weight: bold;
  }
  .answer-option--title,
  .answer-option--text {
    font-family: var(--aiden-answer-option-text-font-family, inherit);
    margin: 0;
  }

  .answer-option--text {
    margin-top: 0.25rem;
  }

  .answer-option--text:empty {
    display: none;
  }

  .answer-option .answer-option--text {
    font-size: clamp(
      12px,
      var(--aiden-answer-option-text-font-size, 14px),
      14px
    );
  }

  /* copied from the global css class because global css is not visible in L&F */
  .screenreader-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap;
    border: 0;
    opacity: 0;
  }
</style>
